import { ReactElement, useContext, useMemo, useState } from 'react';
import { 
  Button, 
  Card, 
  CardContent, 
  CardHeader, 
  Grid, 
  Typography, 
  Alert, 
  Link, 
  IconButton, 
  Collapse,
} from '@mui/material';
import { CIKeysUsageChart, GridItemBreakpoints, LoadingFragment, PlanChip } from '@localstack/ui';
import {
  CloudPodsService,
  ExtensionService,
  GlobalStateContext,
  ComputeService,
  useApiEffect,
  useApiGetter,
  useRoutes,
  useSnackbar,
} from '@localstack/services';
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
} from '@mui/icons-material';

import { 
  LocalStackInstance, 
  LocalStackInstanceType, 
  FeatureMaturityLevel,
  ComputeUsageSummary,
  UsageSummary,
  ProductFeature,
} from '@localstack/types';

import { DEFAULT_INSTANCE_IMAGE, DEFAULT_INSTANCE_SHAPE, ExternalLink } from '@localstack/constants';

import { useAuthProvider } from '~/hooks';
import { LocalStackInstanceHeader } from '~/components/LocalStackInstanceHeader';

import { AppRoute } from '~/config';

import { InstanceCard } from './InstanceCard';

const EPH_INST_ID_PREFIX = 'eph_instance_';


const NEW_EPHEMERAL_INSTANCE: LocalStackInstance = {
  id: EPH_INST_ID_PREFIX,
  name: 'New-instance',
  image: 'localstack/localstack-pro',
  auto_load_pod: undefined,
  endpoint: '',
  description: 'Preview instance',
  instanceType: LocalStackInstanceType.EPHEMERAL_INSTANCE,
  startedAt: (new Date()).getTime(),
  ephemeralInstanceConfig: {
    instance_name: '',
    pod_on_shutdown: true,
    allow_workspace_access: false,
    shape: DEFAULT_INSTANCE_SHAPE,
    env_vars: {},
    image: DEFAULT_INSTANCE_IMAGE,
    labels: {},
    lifetime: undefined,
    requestor: '',
  },
};

function mapComputeUsageSummaryToUsageSummary(computeUsageSummary: ComputeUsageSummary) {
  const { per_day } = computeUsageSummary.usage;

  const sortedKeys = Object.keys(per_day).sort();

  const usagePeriodStart = new Date(sortedKeys[0] ?? '0').getTime()/1000;
  const usagePeriodEnd = new Date(sortedKeys[sortedKeys.length - 1] ?? '0').getTime()/1000;


  const usageSummary: UsageSummary = {
    ci_keys_usage_by_date: {},
    usage_by_product: {},
    usage_by_type: {},
    usage_period_end: usagePeriodEnd,
    usage_period_start: usagePeriodStart,
  };

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const date in per_day) {
    const instanceUsageRecord = per_day[date];
    if (!usageSummary.ci_keys_usage_by_date) {
      usageSummary.ci_keys_usage_by_date = {};
    }
    usageSummary.ci_keys_usage_by_date[date] = {
      subscription_usage: {
        'st': instanceUsageRecord?.compute_credits ?? 0,
      },
    };
  }

  return usageSummary;
}


export const InstanceEphemeral = (): ReactElement => {
  const { instances } = useContext(GlobalStateContext);
  const filteredInstances = instances.filter(i => i.instanceType === LocalStackInstanceType.EPHEMERAL_INSTANCE);

  const { hasFeature } = useAuthProvider();
  const { goto } = useRoutes();

  const hasAccessToEphemeralInstances = hasFeature(ProductFeature.FEATURE_COMPUTE);
  const hasAccessToPods = hasFeature(ProductFeature.FEATURE_PODS);

  const [deleteInstanceId, setDeleteInstanceId] = useState('');

  const { isLoading: isLoadingEphemeralInstances } = useApiGetter(ComputeService, 'listEphemeralInstances', []);
  const { data: publishedExtensions } =
    useApiGetter(ExtensionService, 'listExtensionsMarketplace', [], { defaultValue: [], suppressErrors: true });

  const filteredExtensions = useMemo(
    () => publishedExtensions?.filter(extension => extension.published), [publishedExtensions],
  );

  const extensions = useMemo(() => ([...filteredExtensions ?? []]?.sort((a, b) =>
    (a.display_name ?? 'a')?.localeCompare(b.display_name ?? 'b')) ?? []), [filteredExtensions]);

  const { data: computeUsage, isLoading: isLoadingComputeUsage } =
   useApiGetter(ComputeService, 'getComputeUsage', [], { enable: hasAccessToEphemeralInstances });

  const mapped = computeUsage && mapComputeUsageSummaryToUsageSummary(computeUsage);  

  const [isCollapsed, setIsCollapsed] = useState(true);

  const { data: allPods } =
    useApiGetter(CloudPodsService, 'listCloudPods', [], { defaultValue: [], enable: hasAccessToPods });

  const { createEphemeralInstance, isLoading: isCreatingInstance } = useApiEffect(
    ComputeService, ['createEphemeralInstance'], { revalidate: ['listEphemeralInstances'] },
  );

  const { deleteEphemeralInstance, isLoading: isDeletingInstance } = useApiEffect(
    ComputeService, ['deleteEphemeralInstance'], { revalidate: ['listEphemeralInstances'] },
  );

  const { showSnackbar } = useSnackbar();

  const onCreate = async (instance: LocalStackInstance) => {
    await createEphemeralInstance({ 
      ...instance.ephemeralInstanceConfig, 
      instance_name: instance.name,
      requestor: instance.ephemeralInstance?.requestor ?? '',
    });
  };

  const onRemove = async (instance: LocalStackInstance) => {
    setDeleteInstanceId(instance.id);
    const res = await deleteEphemeralInstance(instance.id);
    if (res) {
      showSnackbar({
        message: 'Successfully removed instance',
        severity: 'success',
      });  
    }
    setDeleteInstanceId('');
  };

  const gridItemBreakpoints: GridItemBreakpoints = { xs: 12 };

  return (
    <>
      {!hasAccessToEphemeralInstances && (
        <Grid item xs={12}>
          <Alert 
            severity='info'
            variant='outlined'
            action={
              <Button style={{ marginRight: '1rem' }} size='small' variant='outlined' color='primary'
                onClick={() => goto(AppRoute.PRICING)}
              >
                Subscribe
              </Button>
            }
          >
            Ephemeral Instances are only available in our paid plans: Starter, Team, and Enterprise.
          </Alert>
        </Grid>
      )}  
      <Grid item xs={12}>
        <Card>
          <CardHeader 
            title='Ephemeral Instances' 
            titleTypographyProps={{ style: { fontWeight: 'bold' } }}
            action={
              <PlanChip 
                planFamily={FeatureMaturityLevel.PREVIEW} 
                showTooltip
              />
            }
          />
          <CardContent>
            <Typography variant="h6">
            Instead of running LocalStack on your machine locally, you can start an ephemeral instance 
            in the cloud. <br/>
            These instances can be accessed via our UI or through an automatically generated endpoint URL. <br/>
            Deploy your application to the instance to share a preview state, collaborate with others,
            or hand it off to QA. <br/>
            For more details on ephemeral instances, please refer to our{' '}
              <Link href={ExternalLink.DOCS_EPHEMERAL_INSTANCES} target='_blank' underline="hover">
                documentation
              </Link>.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        { (!isLoadingComputeUsage || !hasAccessToEphemeralInstances) && (
          <InstanceCard
            instance={ NEW_EPHEMERAL_INSTANCE }
            onSave={ onCreate }
            isSaving={isCreatingInstance}
            pods={allPods ?? []}
            disabled={!hasAccessToEphemeralInstances}
            computeUsage={computeUsage || undefined}
            extensions={extensions ?? []}
          />
        )}
      </Grid> 
      <LoadingFragment
        variant="grid"
        size={4}
        loading={
          (hasAccessToEphemeralInstances && (isLoadingEphemeralInstances || 
          isLoadingComputeUsage))
        }
        gridItemBreakpoints={gridItemBreakpoints}

        height={189}
      >
        {filteredInstances?.map(instance =>
          <Grid item {...gridItemBreakpoints} key={instance.id}>
            <LocalStackInstanceHeader
              isManagementView
              key={instance.id}
              instance={instance}
              onRemove={onRemove}
              isRemovingInstance={
                deleteInstanceId === instance.id ? isDeletingInstance : false
              }
            />
          </Grid>,
        )}
        {
          filteredInstances.length === 0 && hasAccessToEphemeralInstances &&
          <Grid item xs={12}>
            <Alert severity="info">
              No ephemeral instances are running at the moment
            </Alert>
          </Grid>
        }
      </LoadingFragment>
      {mapped && Object.keys(mapped?.ci_keys_usage_by_date ?? {}).length !== 0 && (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Credit consumption"
              titleTypographyProps={{ style: { fontWeight: 'bold' } }}
              action={
                <IconButton
                  size="small"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  {isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </IconButton>
              }
            />
            <Collapse in={!isCollapsed}>
              <CIKeysUsageChart
                data={mapped}
                creditRangePercentages={{
                  green: [0, 70],
                  orange: [70, 90],
                  red: [90, 100],
                  extra: [100, 200],
                }}
                creditsQuota={computeUsage?.policy.quota.compute_credits ?? 0}
                subscriptionId="st"
                leftLabel='Daily credit consumption'
                rightLabel='Total credit consumption'
              />
            </Collapse>
          </Card>
        </Grid>
      )}
    </>
  );
};
