import { useState, ReactElement, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Business as BusinessIcon, ContactMail as AddressIcon, Receipt as TaxIcon } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { Organization, OrganizationType } from '@localstack/types';

import {
  Wizard,
  WizardStep,
  WizardStepType,
  ProgressButton,
  TaxIdTextField,
  ControlledTextField,
  ControlledPhoneField,
  ControlledCountryAutocomplete,
} from '@localstack/ui';

import {
  useApiEffect,
  OrganizationsService,
  getTaxIdExample,
  VALIDATION_RULES, UserService,
} from '@localstack/services';

import { useAuthProvider } from '~/hooks';

const useWizardStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    borderColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
  },
  input: {
    '&:hover > div': {
      background: 'none !important',
    },
    '& label': {
      color: `${theme.palette.primary.contrastText} !important`,
    },
    '&:hover fieldset, &:focus fieldset, & fieldset': {
      borderColor: `${theme.palette.primary.contrastText} !important`,
    },
  },
}));

const AddressForm = (
  { org, onSubmit, loading }:
    { org: Optional<Organization>, onSubmit: (org: Organization) => unknown, loading?: boolean },
) => {
  const classes = useWizardStyles();

  const {
    control,
    handleSubmit,
    formState,
    // eslint-disable-next-line
    // @ts-ignore
  } = useForm<Organization>({ mode: 'all', defaultValues: org });

  const submitHandler = (data: Organization) => {
    if (!formState.isValid) return;
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Box mt={2}>
        <Grid container spacing={2}>
          {org?.orgType === OrganizationType.COMPANY && (((
            <Grid item md={6} sm={12}>
              <ControlledTextField
                control={control}
                className={classes.input}
                required
                name="name"
                label="Company Name"
                placeholder="Company Name"
                fullWidth
                variant="outlined"
                rules={VALIDATION_RULES.required}
              />
            </Grid>
          )))}
          <Grid item md={6} sm={12}>
            <ControlledPhoneField
              control={control}
              className={classes.input}
              required
              name="phone"
              label="Primary Phone"
              placeholder="Primary Phone"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.phone}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <ControlledTextField
              control={control}
              className={classes.input}
              required
              name="email"
              label="Billing Email"
              placeholder="Billing Email"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.email}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <ControlledTextField
              control={control}
              className={classes.input}
              required
              name="address"
              label="Address"
              placeholder="Address"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.required}
            />
          </Grid>
          <Grid item md={4}>
            <ControlledTextField
              control={control}
              className={classes.input}
              required
              name="city"
              label="City"
              placeholder="City"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.required}
            />
          </Grid>
          <Grid item md={4}>
            <ControlledTextField
              control={control}
              className={classes.input}
              name="state"
              label="State"
              placeholder="State"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item md={4}>
            <ControlledTextField
              control={control}
              className={classes.input}
              required
              name="zip"
              label="Zip Code"
              placeholder="Zip Code"
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.required}
            />
          </Grid>
          <Grid item md={12}>
            <ControlledCountryAutocomplete
              control={control}
              name="country"

              rules={VALIDATION_RULES.required}
              inputProps={{
                className: classes.input,
                label: 'Country',
                placeholder: 'Country',
                fullWidth: true,
                variant: 'outlined',
              }}
            />
          </Grid>
        </Grid>
        <Box textAlign="right" mt={2}>
          <ProgressButton
            type="submit"
            loading={loading}
            className={classes.button}
            disabled={!formState.isValid}
            variant="outlined"
            size="large"
          >
            Save
          </ProgressButton>
        </Box>
      </Box>
    </form>
  );
};

const TaxForm = (
  { org, onSubmit, loading }:
    { org: Optional<Organization>, onSubmit: (org: Organization) => unknown, loading?: boolean },
) => {
  const classes = useWizardStyles();

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState,
    // eslint-disable-next-line
    // @ts-ignore
  } = useForm<Organization>({ mode: 'all', defaultValues: org });

  const taxId = watch('tax_id');
  const country = watch('country');

  const submitHandler = (data: Organization) => {
    if (!formState.isValid) return;
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <ControlledCountryAutocomplete
              control={control}
              name="country"
              rules={VALIDATION_RULES.required}
              inputProps={{
                className: classes.input,
                label: 'Country',
                placeholder: 'Country',
                fullWidth: true,
                required: true,
                variant: 'outlined',
              }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TaxIdTextField
              control={control}
              className={classes.input}
              name="tax_id"
              label="TAX ID (VAT)"
              placeholder={getTaxIdExample(country) || ''}
              fullWidth
              variant="outlined"
              rules={VALIDATION_RULES.taxId(country)}
              taxId={taxId}
              country={country}
              onChangeTaxType={(newTaxType) => setValue('tax_id_type', newTaxType || undefined, { shouldDirty: true })}
            />
          </Grid>
        </Grid>
        <Box mt={2} textAlign="right">
          <ProgressButton
            type="submit"
            loading={loading}
            className={classes.button}
            disabled={!formState.isValid}
            variant="outlined"
            size="large"
          >
            Save
          </ProgressButton>
        </Box>
      </Box>
    </form>
  );
};

export const CheckoutWizard = (): ReactElement => {
  const [showWizard, setShowWizard] = useState(true);

  const { userInfo } = useAuthProvider();

  const [addressConfirmed, setAddressConfirmed] = useState(false);
  const [addressHasChanged, setAddressHasChanged] = useState(false);

  const [vatConfirmed, setVatConfirmed] = useState(false);
  const [vatHasChanged, setVatHasChanged] = useState(false);

  const { updateOrganization, isLoading: isOrgMutating } = useApiEffect(
    OrganizationsService,
    ['updateOrganization'],
    { revalidate: ['listOrganizations'], revalidateOtherClient: { client: UserService, methods: ['getUser'] } },
  );

  const wizardSteps = useMemo(() => [
    {
      title: 'Are you purchasing on behalf of a company?',
      condition: ({ org }) => org && org.orgType !== OrganizationType.COMPANY,
      type: WizardStepType.SIMPLE_TEXT,
      icon: <BusinessIcon />,
      loading: isOrgMutating,
      actions: [
        {
          title: 'Yes',
          callback: ({ org }) => org && updateOrganization(
            org.id, { orgType: OrganizationType.COMPANY } as Organization,
          ),
        },
        {
          title: 'No',
          callback: () => { setShowWizard(false); },
        },
      ],
    },
    // Ask if the address assigned to the company is still relevant
    // - do not show if user already confirmed address without a change
    {
      title: 'Is this still your current company (billing) address?',
      text: ({ org }) => <Typography>{org?.address}, {org?.zip}, {org?.city}, {org?.country}</Typography>,
      condition: ({ org }) => org && org.orgType === OrganizationType.COMPANY && org.address && !addressConfirmed,
      type: WizardStepType.SIMPLE_TEXT,
      icon: <AddressIcon />,
      loading: !!isOrgMutating,
      actions: [
        { title: 'Yes', callback: () => setAddressConfirmed(true) },
        { title: 'No', callback: () => { setAddressConfirmed(true); setAddressHasChanged(true); } },
      ],
    },
    // Show user the Address Form in case missing or no longer relevant
    {
      title: 'Please provide address details of your company',
      text: ({ org }) => (
        <AddressForm
          org={org}
          loading={isOrgMutating}
          onSubmit={async (orgData) => {
            if (!org?.id) return;
            await updateOrganization(org.id, orgData);
            setAddressHasChanged(false);
            setAddressConfirmed(true);
          }}
        />
      ),
      condition: ({ org }) => org && org.orgType === OrganizationType.COMPANY && (!org.address || addressHasChanged),
      type: WizardStepType.SIMPLE_TEXT,
    },
    // Ask if the VAT assigned to the company is still relevant
    // - do not show if user already confirmed VAT without a change
    {
      title: 'Is this still valid as your company\'s VAT?',
      text: ({ org }) => <Typography>{org?.tax_id}</Typography>,
      condition: ({ org }) => org && org.orgType === OrganizationType.COMPANY && org.tax_id && !vatConfirmed,
      type: WizardStepType.SIMPLE_TEXT,
      icon: <TaxIcon />,
      loading: !!isOrgMutating,
      actions: [
        { title: 'Yes', callback: () => setVatConfirmed(true) },
        { title: 'No', callback: () => { setVatConfirmed(true); setVatHasChanged(true); } },
      ],
    },
    {
      title: 'Please provide your tax details',
      text: ({ org }) => (
        <TaxForm
          org={org}
          loading={isOrgMutating}
          onSubmit={async (orgData) => {
            if (!org?.id) return;
            await updateOrganization(org.id, orgData);
            setVatHasChanged(false);
            setVatConfirmed(true);
          }}
        />
      ),
      condition: ({ org }) => org && org.orgType === OrganizationType.COMPANY && (!org.tax_id || vatHasChanged),
      type: WizardStepType.SIMPLE_TEXT,
    },
  ] as WizardStep<{ org: Optional<Organization> }>[],
  [isOrgMutating, addressHasChanged, addressConfirmed, vatHasChanged, vatConfirmed],
  );

  return (
    <>
      {showWizard &&
        <Wizard
          args={{ org: userInfo?.org }}
          steps={wizardSteps}
        />
      }
    </>
  );
};
