import { useState, useCallback, ReactNode, ReactElement, MouseEvent } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonProps, useTheme } from '@mui/material';

export type DropdownProps = ButtonProps & {
  label?: string;
  renderButton?: (props: DropdownProps) => JSX.Element;
  children: ReactNode | ReactNode[];
  stopPropagation?: boolean,
};

export const Dropdown = ({ label, renderButton, children, stopPropagation, ...rest }: DropdownProps): ReactElement => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<Optional<HTMLElement>>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    setAnchorEl(event.currentTarget);
  }, []);

  return (
    <>
      {renderButton && renderButton({ label, children, onClick: handleClick, stopPropagation, ...rest })}
      {!renderButton && (
        <>
          {label && (
            <Button
              onClick={handleClick}
              variant="outlined"
              disableElevation
              endIcon={<KeyboardArrowDown />}
              {...rest}
            >
              {label}
            </Button>
          )}
          {!label && (
            <Button
              onClick={handleClick}
              variant="outlined"
              disableElevation
              {...rest}
            >
              <KeyboardArrowDown />
            </Button>
          )}
        </>
      )}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        style={{ zIndex: theme.zIndex.tooltip }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper style={{ borderRadius: 10, maxHeight: 500, overflow: 'auto' }}>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <MenuList autoFocusItem={Boolean(anchorEl)}>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
