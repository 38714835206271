import { Palette } from '@mui/material';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiToggleButton'] => ({
  styleOverrides: {
    root: {
      borderRadius: '8px',
      border: `solid 1px ${palette.primary.main}`,
      color: palette.primary.main,

      '&.Mui-selected': {
        backgroundColor: `${palette.primary.main} !important`,
        color: 'white !important',
        fontWeight: 900,
      },
      '&:hover': {
        color: palette.primary.main,
      },
    },
  },
});
