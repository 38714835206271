import { useState, useEffect, useCallback, ReactElement } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { AuthToken } from '@localstack/types';
import { Logo } from '@localstack/ui';
import { useRoutes } from '@localstack/services';
import { Box, Card, CardContent, Container, Link, Typography } from '@mui/material';

import { useAuthProvider } from '~/hooks';
import { BASE_URL, COGNITO_CLIENT_ID, AppRoute, COGNITO_DOMAIN, SERVICE_BASE_URL } from '~/config';
import { BaseLayout } from '~/layouts';
import { storeAuthToken } from '~/util/storage';

interface CognitoTokenResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
  client_id: string;
  expires_in: number;
  token_type: string;
}

const GET_USER_RETRY_COUNT = 10;
const GET_USER_RETRY_INTERVAL = 1_000; // 1 second

export const SSOCallback = (): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Optional<string>>(null);
  const [goodToGo, setGoodToGo] = useState(false);

  const [searchParams] = useSearchParams();
  const { reloadUserInfo } = useAuthProvider();
  const { goto } = useRoutes();

  const code = searchParams.get('code');

  const authenticate = useCallback(
    async () => {
      try {
        setError(null);
        setLoading(true);

        // NOTE we need a fresh axios instance here without any interceptors,
        // as Cognito is very sensitive to unexpected headers or parameters
        const axiosWithoutInterceptors = axios.create();

        const { data: response } = await axiosWithoutInterceptors.post<CognitoTokenResponse>(
          `https://${COGNITO_DOMAIN}.auth.eu-central-1.amazoncognito.com/oauth2/token`,
          new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: COGNITO_CLIENT_ID,
            redirect_uri: `${BASE_URL}${AppRoute.SSO_CALLBACK}`,
            code,
          } as any), // eslint-disable-line
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': 'application/json',
            },
          },
        );

        const token: AuthToken = {
          token: `Bearer ${response.id_token}`,
          metadata: {
            ExpiresAt: Math.round(new Date().getTime() / 1000) + response.expires_in,
            RefreshToken: response.refresh_token,
          },
        };

        storeAuthToken(token);

        // Run a check to see if user creation is still in progress before redirecting to the dashboard
        for (let i = 0; i < GET_USER_RETRY_COUNT; i++) {
          try {
            // Note we use `fetch` to bypass axios interceptors, otherwise
            // user will get immediately redirected to the sign-in page.
            // eslint-disable-next-line no-await-in-loop
            await fetch(
              `${SERVICE_BASE_URL}/v1/user`,
              { method: 'GET', body: JSON.stringify(token) },
            );

            break; // If we reach this point, user has been created
          } catch (e) {
            // wait before retrying, in case we are too fast today
            // eslint-disable-next-line no-await-in-loop
            await new Promise(r => setTimeout(r, GET_USER_RETRY_INTERVAL));
          }
        }

        // If the retry-block from above fails, user will end up on the sign-in page
        const userInfo = await reloadUserInfo();

        setGoodToGo(true);
        if (userInfo && userInfo.user.signup_survey?.require_finish_account_creation) {
          goto(AppRoute.SIGN_UP, undefined, 'isFinishAccountCreation=true');
        }
        else goto(AppRoute.DASHBOARD);
      } catch (e) {
        setError(e.message ?? 'Unknown error');
      } finally {
        setLoading(false);
      }
    },
    [code],
  );

  useEffect(() => {
    if (!code) return;
    authenticate();
  }, []);

  return (
    <BaseLayout documentTitle="SSO Login" hideNavigation>
      <Box flexGrow={1}>
        <Container>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box textAlign="center">
                  <Logo />
                </Box>
                {!code && (
                  <Box textAlign="center" mt={2}>
                    <Typography color="error">
                      Something went wrong during OAuth Process
                    </Typography>
                  </Box>
                )}
                {error && (
                  <Box textAlign="center" mt={2}>
                    <Typography color="error">{error}</Typography>
                  </Box>
                )}
                {loading && code && (
                  <Box textAlign="center" mt={2}>
                    Please wait while we are finalising your login...
                  </Box>
                )}
                {goodToGo && (
                  <Box textAlign="center" mt={2}>
                    <Typography>
                      We are all set! You will be redirected to the dashboard shortly...{' '}
                      If this does not happen, please click{' '}
                      <Link href={AppRoute.DASHBOARD} underline="hover">on this link</Link>
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </BaseLayout>
  );
};
