import {
  useApiGetter,
  ExtensionService,
  useRoutes,
} from '@localstack/services';

import { ReactElement, useMemo, useState } from 'react';
import {
  Box, Button, Card, CardActions, CardContent,
  CardHeader, Grid, Link, Typography, Alert, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import { InstanceSelectorButton, LoadingFragment, NavLink } from '@localstack/ui';
import { Search as BrowseIcon, Build as BuildIcon } from '@mui/icons-material';

import { Extension, LocalStackInstance, PlanFamily } from '@localstack/types';

import { ExternalLink } from '@localstack/constants';

import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import { useFeatureFlags } from '~/util/FeatureFlags';

import { ExtensionCard, gridItemBreakpoints } from './components/ExtensionCard';


enum ToggleState {
  BROWSE = 'browse',
  BUILD = 'build',
}

export const ExtensionsLibrary = (): ReactElement => {
  const { data: publishedExtensions, isLoading: loadingPublishedExtensions, hasError: hasPublishedExtensionsError } =
    useApiGetter(ExtensionService, 'listExtensionsMarketplace', [], { defaultValue: [], suppressErrors: true });

  const extensions = useMemo(() => ([...publishedExtensions ?? []]?.sort((a, b) =>
    (b.created ?? 0) - (a.created ?? 0)) ?? []), [publishedExtensions]);

  const featureFlags = useFeatureFlags();
  const { goto } = useRoutes();

  const instanceSelectorCallback = (instance: LocalStackInstance, extension: Extension) => {
    goto(AppRoute.EXTENSIONS_MANAGE, { iid: instance.id }, `extension=${extension.name}`);
  };

  const [toggleState, setToggleState] = useState<ToggleState>(ToggleState.BROWSE);

  const instanceSelectorButton = (extension: Extension) => (
    <InstanceSelectorButton
      callback={(instance: LocalStackInstance) => instanceSelectorCallback(instance, extension)}
      label='Install on Instance'
    />
  );

  return (
    <CustomerLayout
      title="Extensions Library"
      planFamily={PlanFamily.PRO_PLANS}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h5'>
            Extend the functionality of LocalStack by adding new services and features via our extension mechanism.
          </Typography>
          <Typography variant='h5'>
            Learn more about extensions and how they work in
            {' '}
            <Link
              href={ExternalLink.DOCS_EXTENSIONS}
              target='_blank'
              rel='noreferrer'
              underline="hover"
            >
              our documentation
            </Link>
            .
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '1rem' }}>
          <ToggleButtonGroup
            exclusive
            onChange={(_, value) => setToggleState(value || toggleState)}
            value={toggleState}
          >
            <ToggleButton
              value={ToggleState.BROWSE}
            >
              <Box display="flex" alignItems="center">
                <BrowseIcon /><Box mr={1} />
                <span>Browse</span>
              </Box>
            </ToggleButton>
            <ToggleButton
              value={ToggleState.BUILD}
            >
              <Box display="flex" alignItems="center">
                <BuildIcon /><Box mr={1} />
                <span>Build</span>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {toggleState === ToggleState.BROWSE && (
          <>
            {hasPublishedExtensionsError &&
              <Grid item xs={12}>
                <Alert
                  severity='warning'
                  style={{ width: '100%' }}
                >
                  An error occurred while fetching available extensions.
                </Alert>
              </Grid>
            }
            <LoadingFragment
              loading={loadingPublishedExtensions}
              variant="grid"
              size={9}
              gridItemBreakpoints={gridItemBreakpoints()}
              height={170}
            >
              {
                extensions?.map(
                  (extension) =>
                    <ExtensionCard
                      key={extension.name}
                      extension={extension}
                      instanceSelectorButton={instanceSelectorButton(extension)}
                    />,
                )
              }
            </LoadingFragment>
          </>
        )}
        {toggleState === ToggleState.BUILD && (
          <>
            <Grid item xs={12}>
              <Typography variant='h5'>
                Want to build your own extension?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title='1. Check out our documentation' />
                <CardContent>
                  <Typography variant='h6'>
                    Building your own extension is easy. Check out our
                    {' '}
                    <Link
                      href={ExternalLink.DOCS_DEVELOPING_EXTENSIONS}
                      target='_blank'
                      rel='noreferrer'
                      underline="hover"
                    >
                      documentation
                    </Link>
                    {' '}
                    around developing extensions.
                  </Typography>
                  <Typography variant='h6'>
                    After implementing your extension be sure to properly package it
                    with a  <code>setup.cfg</code>  file.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title='2. Publish your extension on GitHub' />
                <CardContent>
                  <Typography variant='h6'>
                    Once your extension is ready to be used, release it on a public GitHub repository.
                  </Typography>
                  <Typography variant='h6'>
                    To make your extension easily installable for everyone generate an
                    extension badge for your extension
                    {' '}
                    <NavLink
                      to={AppRoute.EXTENSIONS_REMOTE}
                      target='_blank'
                    >
                      on this page
                    </NavLink>
                    .
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <Typography variant='h6'>
                      The resulting badge should look like this
                    </Typography>
                    <img alt='LocalStack extension badge' src={ExternalLink.BADGE_EXTENSIONS} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title='3. Install your extension via the standalone installer' />
                <CardContent>
                  <Typography variant='h6'>
                    After including the badge on your repository,
                    everyone with a pro subscription should be able to install it by clicking on the badge.
                  </Typography>
                  <Typography variant='h6'>
                    Feel free to try out the installation flow with our
                    {' '}
                    <Link
                      href={ExternalLink.EXTENSION_HELLO_WORLD}
                      target='_blank'
                      rel='noreferrer'
                      underline="hover"
                    >
                      hello-world extension
                    </Link>
                    .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {featureFlags.showExtensionSubmission &&
              <Grid item xs={12}>
                <Card>
                  <CardHeader title='4. Submit your extension to our marketplace' />
                  <CardContent>
                    <Typography variant='h6'>
                      After ensuring that your extension is working properly, submit it to our marketplace.
                    </Typography>
                    <Typography variant='h6'>
                      The submission process can be started by clicking on the button below.
                    </Typography>
                  </CardContent>
                  <CardActions style={{ justifyContent: 'start' }}>
                    <Button variant='contained' color='primary' onClick={() => goto(AppRoute.EXTENSIONS_SUBMIT)}>
                      Submit Extension
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            }
          </>
        )}
      </Grid>
    </CustomerLayout>
  );
};
