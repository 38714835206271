import { ReactElement } from 'react';
import { AdminWidget } from '@localstack/types';
import { Typography, Box, Grid } from '@mui/material';

import { AdminLayout } from '~/layouts';
import { useAuthProvider } from '~/hooks';
import { Searchbar } from '~/layouts/Admin/components';
import { retrieveAuthToken } from '~/util/storage';

import { DashboardWidget } from './components';

export const Dashboard = (): ReactElement => {
  const { userInfo } = useAuthProvider();

  const authToken = retrieveAuthToken()?.token || '';

  return (
    <AdminLayout
      title="Admin Dashboard"
    >
      <Box mt={4} mb={4}>
        <Typography align="center" variant="h2">
          Hej {userInfo?.user?.firstname}, what would you like to do?
        </Typography>
        <Searchbar size="medium" />
      </Box>

      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <DashboardWidget
            title="Platform CPU Utilization"
            widget={AdminWidget.ECS_CPU_UTILIZATION}
            authToken={authToken}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <DashboardWidget
            title="Platform Memory Utilization"
            widget={AdminWidget.ECS_MEMORY_UTILIZATION}
            authToken={authToken}
          />
        </Grid>
      </Grid>
    </AdminLayout>
  );
};
