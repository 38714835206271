import { ReactElement } from 'react';
import { StructureRenderer, ControlledTextarea } from '@localstack/ui';
import { ResolvedMagicSchema, Feedback } from '@localstack/types';
import { FormProvider, useForm } from 'react-hook-form';
import { Typography, Grid } from '@mui/material';

const RATING_NUMBERS = ['1', '2', '3', '4', '5'];

// TODO: think about custom names instead of `motive_local` etc.
const MOTTIVATION_SCHEMA: ResolvedMagicSchema = {
  type: 'structure',
  members: [
    {
      name: 'motive_local',
      type: 'boolean',
      documentation: 'Increased agility/efficiency through local development',
    },
    {
      name: 'motive_regulated',
      type: 'boolean',
      documentation: 'Working in a regulated environment with restricted access to Cloud',
    },
    {
      name: 'motive_costs',
      type: 'boolean',
      documentation: 'Cost savings, as compared to real Cloud environment',
    },
    {
      name: 'motive_experimenting',
      type: 'boolean',
      documentation: 'Rapidly experimenting without the risk of breaking production',
    },
    {
      name: 'motive_other',
      type: 'boolean',
      documentation: 'Other reason',
    },
    {
      name: 'motiveText',
      type: 'string',
      documentation: 'Please tell us what you think',
    },
  ],
};

const RATE_FEATURES_SCHEMA: ResolvedMagicSchema = {
  type: 'structure',
  members: [
    {
      name: 'feat_collab',
      type: 'string',
      documentation: 'Team collaboration: your LocalStack as a persistent, shareable sandbox',
      enum: RATING_NUMBERS,
      required: true,
    },
    {
      name: 'feat_chaos',
      type: 'string',
      documentation: 'Chaos engineering: use LocalStack for fault injection and reliability testing',
      enum: RATING_NUMBERS,
      required: true,
    },
    {
      name: 'feat_insights',
      type: 'string',
      documentation: 'Analytics & reporting: cost analysis, build failure prediction, app optimization',
      enum: RATING_NUMBERS,
      required: true,
    },
  ],
};

const SENIORITY_SCHEMA: ResolvedMagicSchema = {
  type: 'structure',
  members: [
    {
      name: 'seniority',
      type: 'string',
      documentation: 'What is your level of seniority?',
      enum: ['junior', 'senior', 'principal'],
      required: true,
    },
    {
      name: 'additionalText',
      type: 'string',
      documentation: 'Any other feedback you would like to share with us?',
    },
  ],
};

interface SurveyProps {
  formId: string;
  onSubmit: (data: Feedback) => unknown;
}

export const MotivationSurvey = ({ formId, onSubmit }: SurveyProps): ReactElement => {
  const methods = useForm<Feedback>({ mode: 'all' });
  const { formState: { isValid } } = methods; // very important to unwrap it here, as useForm returns a proxy

  const submitHandler = (data: Feedback) => {
    if (!isValid) return;
    onSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={methods.handleSubmit(submitHandler)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" paragraph>
              What is your primary motivation for using LocalStack?
            </Typography>
            <StructureRenderer
              member={MOTTIVATION_SCHEMA}
              tokens={[]}
              hideNames
              fieldConditions={{
                motiveText: ['$motive_other', '===', true],
              }}
              externalFields={{
                '^additionalText': (control, name) => (
                  <ControlledTextarea control={control} name={name} minRows={10} />
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" paragraph>
              How would you rate the importance of the following new features? (5=highest)
            </Typography>
            <StructureRenderer
              member={RATE_FEATURES_SCHEMA}
              tokens={[]}
              hideNames
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" paragraph>
              What is your level of seniority?
            </Typography>
            <StructureRenderer
              member={SENIORITY_SCHEMA}
              tokens={[]}
              hideNames
              externalFields={{
                '^additionalText': (control, name) => (
                  <ControlledTextarea control={control} name={name} minRows={10} />
                ),
              }}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
