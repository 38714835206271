import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  PageTitle,
  Dropdown,
  ConfirmableButton,
  AgwRestApiKeysTable,
} from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiKeys = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { restApiId } = useParams<'restApiId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });
  const { data: keys, mutate } = useAwsGetter('APIGateway', 'getApiKeys', [], { clientOverrides });

  const { deleteApiKey, isLoading } = useAwsEffect(
    'APIGateway',
    ['deleteApiKey'],
    {
      revalidate: ['getApiKeys', 'getApiKey'],
      clientOverrides,
    },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((apiKey) => deleteApiKey({ apiKey }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Keys"
      title={
        <PageTitle
          title="API Keys"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
            [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
            ['API Keys', null],
          ]}
        />
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW1_API_KEY_CREATE, { restApiId })}>
            Create Key
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Key(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Keys will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwRestApiKeysTable
          apiKeys={keys?.items || []}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
