import { useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Logo } from '@localstack/ui';
import { useRoutes, useApiGetter, OrganizationsService } from '@localstack/services';
import { Box, Card, CardContent, Container, Link, Typography } from '@mui/material';

import { COGNITO_CLIENT_ID, COGNITO_DOMAIN, BASE_URL, AppRoute } from '~/config';
import { BaseLayout } from '~/layouts';

const FETCH_PROVIDERS_BY_IDENTIFIER = ['google'];

export const SSOStart = (): ReactElement => {
  const { orgId, idpName } = useParams<'orgId' | 'idpName'>() as { orgId: string, idpName: string };
  const fetch_by_identifier = FETCH_PROVIDERS_BY_IDENTIFIER.includes(idpName) ? 'true' : undefined;

  const { goto } = useRoutes();

  const { data, isLoading, error } =
    useApiGetter(OrganizationsService, 'getIdentityProvider', [orgId, idpName]);

  const idpNameOrIdentifier = fetch_by_identifier ? 'idp_identifier' : 'identity_provider';

  const ssoLink = `https://${COGNITO_DOMAIN}.auth.eu-central-1.amazoncognito.com/oauth2/authorize?response_type=code` +
    `&client_id=${COGNITO_CLIENT_ID}&redirect_uri=${BASE_URL}${AppRoute.SSO_CALLBACK}` +
    `&${idpNameOrIdentifier}=${orgId}-${idpName}`;

  useEffect(() => {
    if (!data && !isLoading) {
      goto(AppRoute.NOT_FOUND);
    }

    if (data && !isLoading) {
      window.location.href = ssoLink;
    }
  }, [data, isLoading]);

  return (
    <BaseLayout documentTitle="SSO Login" hideNavigation>
      <Box flexGrow={1}>
        <Container>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box textAlign="center">
                  <Logo />
                </Box>
                {error && (
                  <Box textAlign="center" mt={2}>
                    <Typography color="error">{error.message}</Typography>
                  </Box>
                )}
                {isLoading && (
                  <Box textAlign="center" mt={2}>
                    We are preparing an identity provider...
                  </Box>
                )}
                {data && (
                  <Box textAlign="center" mt={2}>
                    <Typography>
                      You are being redirected to the login page of your identity provider.{' '}
                      If this does not happen automatically, please{' '}
                      <Link href={ssoLink} underline="hover">follow this link</Link>
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </BaseLayout>
  );
};
