import { Logo } from '@localstack/ui';
import { Link, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        paddingTop: '2em',
        paddingBottom: '2em',
        borderBottom: '1px solid',
        borderColor: `${theme.palette.divider} !important`,
        '.collapsed &': {
          display: 'none',
        },
      },
      logoLink: {
        lineHeight: theme.spacing(0),
      },
    },
  ),
);


export const WorkspaceSelector = (): ReactElement => {

  const classes = useStyles();

  return (
    <div data-name="workspace selector" className={classes.root}>
      <Link
        className={classes.logoLink}
        href="/"
        title="LocalStack Dashboard"
        underline="hover"
      >
        <Logo />
      </Link>
    </div>
  );
};
