import { LocalStackEventType, observable, STORAGE_KEY_THEME, ThemeType } from '@localstack/integrations';
import {
  UserService,
  hashEmail,
  useApiGetter,
  useRoutes,
} from '@localstack/services';
import { Dropdown } from '@localstack/ui';
import {
  Avatar,
  Badge,
  Box,
  Container,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ArrowDropDown,
  NightsStay as DarkModeIcon,
  NotificationsNone as NotificationsIcon,
} from '@mui/icons-material';
import React, { ReactElement, useMemo } from 'react';

import { AppRoute } from '~/config';
import { getNotificationSettings, getUserInfo } from '~/util/storage';

import { AnnouncementModal } from '~/components';

import { BaseLayout, BaseLayoutProps } from '../Base';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      borderBottom: '1px solid',
      color: theme.palette.divider,
      borderColor: theme.palette.divider,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      '& a': {
        marginRight: theme.spacing(3),
      },
    },
    toolBox: {
      backgroundColor: theme.palette.background.default,
      padding: '1em',
      borderBottom: '1px solid',
      borderColor: `${theme.palette.divider} !important`,
      justifyContent: 'center',

      '.collapsed &': {
        padding: 0,
        paddingTop: '1em',
        paddingBottom: '1em',
      },

    },
    iconsContainer: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      '.collapsed &': {
        flexDirection: 'column-reverse',
      },
    },
    navIcon: {
      color: theme.palette.text.secondary,
    },
    avatar: {
      backgroundColor: theme.palette.text.secondary,

      '.collapsed &': {
        width: '22px',
        height: '22px',
      },
    },
    dropDownArrow: {
      '.collapsed &': {
        display: 'none',
      },
    },
    navLink: {
      marginRight: theme.spacing(3),
    },
    navLinkActive: {
      textDecoration: 'underline',
    },
    contentContainer: {
      flex: 1,
      display: 'flex',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    content: {
      flex: 1,
      maxWidth: '100%',
    },
    globalSignOut: {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    regionDropdown: {
      width: '100%',
      marginTop: '1em',
    },
  }),
);

export type AuthenticatedLayoutProps = BaseLayoutProps & {
  tabs?: React.ReactNode;
  sidebar?: React.ReactNode;
};

export const AuthenticatedLayout = ({
  children,
  sidebar,
  tabs,
  maxWidth = 'lg',
  fullScreen,
  ...rest
}: AuthenticatedLayoutProps): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();

  const userDetails = getUserInfo();

  const { data: notifications, mutate } = useApiGetter(UserService, 'listNotifications', []);

  const unreadNotifications = useMemo(() => notifications?.filter(n => !n.read), [notifications]);
  const enableNotifications = getNotificationSettings();

  const currentTheme = localStorage.getItem(STORAGE_KEY_THEME);
  const announcements = useMemo(() =>
    unreadNotifications?.filter(item => item.reference_id) ?? [], [unreadNotifications]);


  const handleChangeTheme = (theme: ThemeType) => {
    observable.notify({ eventType: LocalStackEventType.THEME_UPDATE, data: { theme } });
  };

  const containerMaxWidth = fullScreen ? false : maxWidth;

  return (
    <BaseLayout
      {...rest}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      toolbox={
        // Toolbox
        <Box display="flex" alignItems="center" className={classes.toolBox}>
          <Box className={classes.iconsContainer}>
            {/* Notifications */}
            {enableNotifications &&
              <Tooltip title={`You have ${unreadNotifications?.length ?? 0} new notification(s)`}>
                <IconButton size="small" onClick={() => goto(AppRoute.NOTIFICATIONS)}>
                  {unreadNotifications?.length ?
                    <Badge variant='dot' color='primary'>
                      <NotificationsIcon color='primary' />
                    </Badge>
                    :
                    <NotificationsIcon />
                  }
                </IconButton>
              </Tooltip>
            }

            {/* Theme selector */}
            <Dropdown
              size="small"
              renderButton={(props: any) => ( // eslint-disable-line
                <IconButton {...props} size="small">
                  <DarkModeIcon />
                  <ArrowDropDown className={classes.dropDownArrow} />
                </IconButton>
              )}
            >
              <RadioGroup
                value={currentTheme}
                onChange={(e) => handleChangeTheme(e.target.value as ThemeType)}
              >
                <FormControlLabel value="system" control={<Radio color="primary" />} label="System Theme" />
                <FormControlLabel value="light" control={<Radio color="primary" />} label="Light Theme" />
                <FormControlLabel value="dark" control={<Radio color="primary" />} label="Dark Theme" />
              </RadioGroup>
            </Dropdown>

            {/* Avatar */}
            <Dropdown
              size="small"
              renderButton={(props: any) => ( // eslint-disable-line
                <IconButton {...props} size="small">
                  <Avatar
                    className={classes.avatar}
                    src={`https://www.gravatar.com/avatar/${hashEmail(userDetails?.user.email || '')}?d=db`}
                  />
                  <ArrowDropDown className={classes.dropDownArrow} />
                </IconButton>
              )}
            >
              <MenuItem onClick={() => goto(AppRoute.SIGN_OUT)}>Sign out</MenuItem>
            </Dropdown>
          </Box>
        </Box>
      }
    >
      {tabs && (
        <div id="tabs" className={classes.tabs}>
          <Container maxWidth={containerMaxWidth}>{tabs}</Container>
        </div>
      )}

      <Container maxWidth={containerMaxWidth} className={classes.contentContainer}>
        {sidebar && <div>{sidebar}</div>}
        <AnnouncementModal
          announcements={announcements}
          revalidate={mutate}
        />
        <main className={classes.content}>
          {children}
        </main>
      </Container>
    </BaseLayout>
  );
};
