import { MembershipRole } from '@localstack/types';

import { useAuthProvider } from '~/hooks';

import { getUserInfo } from './storage';

type FeatureFlagsType = {
  showCIProjects: boolean,
  showExtensionSubmission: boolean,
}

export const useFeatureFlags: () => FeatureFlagsType = () => {
  const userInfo = getUserInfo();
  const organization = userInfo?.org;
  const { can } = useAuthProvider();

  return {
    showCIProjects: !!organization?.settings?.features?.ci_projects,
    showExtensionSubmission: can(MembershipRole.PLATFORM_ADMIN),
  };
};
