import { useState, useEffect, useCallback, useMemo, ReactElement } from 'react';
import { STORAGE_KEY_SURVEYS } from '@localstack/constants';
import { useApiEffect, useApiGetter, MarketingService } from '@localstack/services';
import { ProgressButton } from '@localstack/ui';
import { Feedback } from '@localstack/types';
import { shuffle } from 'lodash';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Divider,
  Box,
  Button,
  Drawer,
  Container,
} from '@mui/material';

import { useAuthProvider } from '~/hooks';

import { MotivationSurvey } from './MotivationSurvey';

type SurveyComponentType = typeof MotivationSurvey

interface SurveysState {
  [key: string]: {
    cancelled?: number;
  }
}

type SurveyConfiguration = Map<string, {
  component: SurveyComponentType,
  title: string,
  delayAfterPostpone?: number,
  delayAfterSubmit?: number,
  repeat?: boolean
}>

const SURVEYS_CONFIGURATION: SurveyConfiguration = new Map([
  ['mainMotivation', {
    title: 'LocalStack Survey',
    component: MotivationSurvey,
    delayAfterPostpone: 60 * 60 * 24 * 14, // 14 days in seconds
    delayAfterSubmit: 60 * 60 * 24 * 45, // 45 days in seconds,
    repeat: true,
  }],
]);

const isWithinTimeout = (time: Optional<number>, timeout: Optional<number>): boolean => {
  const dateNowInSeconds = Date.now() / 1000;
  return time && timeout ? dateNowInSeconds >= time + timeout : false;
};

export const SurveyProvider = (): ReactElement => {
  const [showPrompt, setShowPrompt] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const { userInfo } = useAuthProvider();

  const [localSurveys, setLocalSurveys] =
    useState<SurveysState>(JSON.parse(localStorage.getItem(STORAGE_KEY_SURVEYS) ?? '{}'));

  const availableSurveys = useMemo(() => Array.from(SURVEYS_CONFIGURATION.keys()), []);

  const { storeFeedback, isLoading: isCreating } = useApiEffect(
    MarketingService,
    ['storeFeedback'],
    { revalidate: ['getFeedbacks'] },
  );

  const { data: feedbacks, isLoading, error } =
    useApiGetter(MarketingService, 'getFeedbacks', [], { enable: !!userInfo });

  const sortedFeedbacks = useMemo(() => feedbacks?.sort((ls, rs) => rs.time - ls.time), [feedbacks?.length]);

  const potentialSurveyIds = useMemo(
    () => availableSurveys.filter(
      (id) => {
        const surveyConfig = SURVEYS_CONFIGURATION.get(id);
        const answeredSurvey = sortedFeedbacks?.find((f) => f.survey_id === id);

        // if local survey was postponed check if we waited enough to ask again
        if (localSurveys[id]?.cancelled) {
          return isWithinTimeout(localSurveys[id]?.cancelled, surveyConfig?.delayAfterPostpone);
        }
        // if survey is already answered check we waited enough before asking again
        if (answeredSurvey && surveyConfig?.repeat) {
          return isWithinTimeout(answeredSurvey.time, surveyConfig?.delayAfterSubmit);
        }
        // if survey is already answered and should not be repeated
        if (answeredSurvey && !surveyConfig?.repeat) return false;
        // survey has never been answered
        return true;
      },
    ),
    [availableSurveys, sortedFeedbacks],
  );

  const loading = isCreating || isLoading;

  // pick a random survey in case we have multiple candidates
  const surveyIdToShow = shuffle(potentialSurveyIds)[0];
  const surveyConfig = SURVEYS_CONFIGURATION.get(surveyIdToShow ?? '');
  const SurveyComponent = surveyConfig?.component;

  const handleSumitSurvey = useCallback(
    async (data: Feedback) => {
      if (!surveyIdToShow) return;
      await storeFeedback({ survey_id: surveyIdToShow, responses: data });
      setShowPrompt(false);
      setShowForm(false);
    },
    [surveyIdToShow],
  );

  const handlePostponeSurvey = useCallback(
    async () => {
      if (!surveyIdToShow) return;
      setLocalSurveys({ ...localSurveys, [surveyIdToShow]: { cancelled: Date.now() } });
      setShowPrompt(false);
      setShowForm(false);
    },
    [surveyIdToShow, localSurveys],
  );

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY_SURVEYS, JSON.stringify(localSurveys));
  }, [localSurveys]);

  if (isLoading || !SurveyComponent || error) return <></>;

  return (
    <>
      <Drawer open={showPrompt && !showForm} anchor="bottom" variant="persistent">
        <Box m={3}>
          <Container>
            <DialogActions>
              <Box flexGrow={1}>
                <Typography align="left">
                  Help us improve LocalStack by answering a few questions.
                </Typography>
              </Box>
              <Button
                data-survey-later-button
                onClick={handlePostponeSurvey}
              >
                Ask Me Later
              </Button>
              <ProgressButton
                loading={loading}
                onClick={() => setShowForm(true)}
                color="primary"
                variant="contained"
              >
                Continue
              </ProgressButton>
            </DialogActions>
          </Container>
        </Box>
      </Drawer>
      <Dialog open={showForm} fullWidth>
        <DialogTitle>{surveyConfig?.title}</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            Please help us make LocalStack even better, by answering the short survey below.
          </Typography>
          <Divider />
          <Box mt={2}>
            <SurveyComponent formId="survey" onSubmit={handleSumitSurvey} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePostponeSurvey}>
            Ask Me Later
          </Button>
          <ProgressButton
            loading={loading}
            type="submit"
            form="survey"
            color="primary"
            variant="contained"
          >
            Submit
          </ProgressButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
