/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiKey } from '@localstack/types';
import type { CancellationDetails } from '@localstack/types';
import type { Discount } from '@localstack/types';
import type { Invoice } from '@localstack/types';
import type { OrganizedApiKeys } from '@localstack/types';
import type { Plan } from '@localstack/types';
import type { Subscription } from '@localstack/types';
import type { SubscriptionCiKeys } from '@localstack/types';
import type { TaxDetails } from '@localstack/types';
import type { UsageSummary } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionService {

    /**
     * @returns Plan
     * @throws ApiError
     */
    public static listPlans(): CancelablePromise<Array<Plan>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans',
        });
    }

    /**
     * @param planId
     * @param promotionCode
     * @returns any
     * @throws ApiError
     */
    public static getPromotion(
        planId: string,
        promotionCode: string,
    ): CancelablePromise<Discount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans/{plan_id}/promotions/{promotion_code}',
            path: {
                'plan_id': planId,
                'promotion_code': promotionCode,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Subscription
     * @throws ApiError
     */
    public static createSubscription(
        requestBody?: {
            plan: string;
            payment_id?: string;
            seats?: number;
            products?: Record<string, any>;
            promo_code?: string;
        },
    ): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plans/subscriptions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Subscription
     * @throws ApiError
     */
    public static listSubscriptions(): CancelablePromise<Array<Subscription>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans/subscriptions',
        });
    }

    /**
     * @param subscriptionId
     * @param requestBody
     * @returns Subscription
     * @throws ApiError
     */
    public static updateSubscription(
        subscriptionId: string,
        requestBody?: {
            plan?: string;
            payment_id?: string;
            seats?: number;
            promo_code?: string;
        },
    ): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/plans/subscriptions/{subscription_id}',
            path: {
                'subscription_id': subscriptionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param subscriptionId
     * @returns Subscription
     * @throws ApiError
     */
    public static getSubscription(
        subscriptionId: string,
    ): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans/subscriptions/{subscription_id}',
            path: {
                'subscription_id': subscriptionId,
            },
        });
    }

    /**
     * @returns ApiKey
     * @throws ApiError
     */
    public static issueUserKey(): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plans/subscriptions/keys',
        });
    }

    /**
     * @param subscriptionId
     * @returns UsageSummary
     * @throws ApiError
     */
    public static getUsage(
        subscriptionId: any,
    ): CancelablePromise<UsageSummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans/subscriptions/{subscription_id}/usage',
            path: {
                'subscription_id': subscriptionId,
            },
        });
    }

    /**
     * @param includeDeleted
     * @returns OrganizedApiKeys
     * @throws ApiError
     */
    public static listKeys(
        includeDeleted?: boolean,
    ): CancelablePromise<OrganizedApiKeys> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans/keys',
            query: {
                'include_deleted': includeDeleted,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ApiKey
     * @throws ApiError
     */
    public static createKey(
        requestBody?: {
            member_email?: string;
            subscription_id: string;
        },
    ): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plans/keys',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param keyId
     * @param requestBody
     * @returns ApiKey
     * @throws ApiError
     */
    public static updateKey(
        keyId: any,
        requestBody?: {
            member_email?: string;
            name?: string;
        },
    ): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/plans/keys/{key_id}',
            path: {
                'key_id': keyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param keyId
     * @returns ApiKey
     * @throws ApiError
     */
    public static deleteKey(
        keyId: string,
    ): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/plans/keys/{key_id}',
            path: {
                'key_id': keyId,
            },
        });
    }

    /**
     * @param keyId
     * @returns ApiKey
     * @throws ApiError
     */
    public static rotateKey(
        keyId: any,
    ): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plans/keys/{key_id}/rotate',
            path: {
                'key_id': keyId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ApiKey
     * @throws ApiError
     */
    public static createCiKey(
        requestBody?: {
            subscription_id: string;
            name: string;
        },
    ): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plans/ci-keys',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SubscriptionCiKeys
     * @throws ApiError
     */
    public static listCiKeys(): CancelablePromise<Array<SubscriptionCiKeys>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans/ci-keys',
        });
    }

    /**
     * @param keyId
     * @param requestBody
     * @returns ApiKey
     * @throws ApiError
     */
    public static updateCiKey(
        keyId: any,
        requestBody?: {
            name?: string;
        },
    ): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/plans/ci-keys/{key_id}',
            path: {
                'key_id': keyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param keyId
     * @returns ApiKey
     * @throws ApiError
     */
    public static deleteCiKey(
        keyId: string,
    ): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/plans/ci-keys/{key_id}',
            path: {
                'key_id': keyId,
            },
        });
    }

    /**
     * @param keyId
     * @returns ApiKey
     * @throws ApiError
     */
    public static rotateCiKey(
        keyId: any,
    ): CancelablePromise<ApiKey> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plans/ci-keys/{key_id}/rotate',
            path: {
                'key_id': keyId,
            },
        });
    }

    /**
     * @param subId
     * @param requestBody
     * @returns Subscription
     * @throws ApiError
     */
    public static cancelSubscription(
        subId: any,
        requestBody?: CancellationDetails,
    ): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/plans/subscriptions/{sub_id}',
            path: {
                'sub_id': subId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Invoice
     * @throws ApiError
     */
    public static listInvoices(): CancelablePromise<Array<Invoice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans/invoices',
        });
    }

    /**
     * @param planId
     * @param seats
     * @param promoCode
     * @returns TaxDetails
     * @throws ApiError
     */
    public static calculateTax(
        planId: string,
        seats: number,
        promoCode?: string,
    ): CancelablePromise<TaxDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans/{plan_id}/tax',
            path: {
                'plan_id': planId,
            },
            query: {
                'seats': seats,
                'promo_code': promoCode,
            },
        });
    }

}
