import { ReactElement } from 'react';

import { CustomerLayout } from '~/layouts';

import { Settings as AccountSettings } from './components';

export const Settings = (): ReactElement => (
  <CustomerLayout
    title="Settings"
  >
    <AccountSettings />
  </CustomerLayout>
);
