// Layout for unauthed users: sign in, sign up, password recovery
import { Layout, LocalStackInstance } from '@localstack/types';
import { Breakpoint, Theme, lighten, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';

import { Box, Button, Container, IconButton, Link, Typography, useMediaQuery } from '@mui/material';

import classNames from 'classnames';

import {
  BaseLayoutContext,
  ComputeService,
  GlobalStateContext,
  MARKER_IDS,
  TestMarkerSpan,
  getSelectedInstance,
  useApiEffect,
  useRoutes,
  useSnackbar,
} from '@localstack/services';

import { AllowedPlanChipPlans, AwsAccountField, AwsRegionDropdown, PlanChip } from '@localstack/ui';

import { ExternalLink } from '@localstack/constants';

import { LocalStackInstanceHeader } from '~/components/LocalStackInstanceHeader';
import { NewsBanner } from '~/components/NewsBanner';
import { PlanUpgradeBanner } from '~/components/PlanUpgradeBanner';
import { WorkspaceSelector } from '~/components/WorkspaceSelector';
import { AppRoute } from '~/config';

export interface BaseLayoutProps extends Layout {
  pageName?: string;
  navigation?: React.ReactNode;
  searchbar?: React.ReactNode;
  toolbox?: React.ReactNode;
  hideNavigation?: boolean;
  planFamily?: AllowedPlanChipPlans;
  maxWidth?: Breakpoint;
  forceShowInstanceHeader?: boolean;
  fullScreen?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: lighten(theme.palette.background.default, 0.03),
    },
    pageContainer: {
      display: 'flex',
      minHeight: '100vh',
      borderColor: `${theme.palette.divider} !important`,
      borderTop: '4px solid',
      borderBottom: '1px solid',
    },
    pageContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    mainContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    appBar: {
      boxShadow: 'none',
    },
    sidebar: {
      flexGrow: 0,
      flexShrink: 0,
      borderColor: `${theme.palette.divider} !important`,
      borderRight: '4px solid',
      backgroundColor: theme.palette.background.paper,

      '.floating&': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        minHeight: '100vh',
        borderBottom: '4px solid',
      },
    },
    menu: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    burger: {
      display: 'none',

      '.collapsed &': {
        display: 'unset',
      },
    },
    nav: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      borderColor: `${theme.palette.divider} !important`,
      borderBottom: '1px solid',
      backgroundColor: theme.palette.background.default,
    },
    actions: {
      flexGrow: 1,
      display: 'flex',
      zIndex: 1,
      alignItems: 'center',
      '& > *': {
        marginLeft: theme.spacing(2),
      },
      justifyContent: 'right',
    },
    footer: {
      background: theme.palette.background.paper,
      color: theme.palette.action.disabled,
      borderTop: '1px solid',
      borderColor: theme.palette.divider,
    },
    mobileMenu: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: theme.palette.background.default,
    },
    burgerContainer: {
      borderColor: `${theme.palette.divider} !important`,
      borderBottom: '1px solid',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
      '.floating &': {
        justifyContent: 'right',
      },
    },
    regionDivider: {
      borderLeft: 'solid 1px',
      width: 0,
      height: '2em',
      borderColor: theme.palette.divider,
    },
  }),
);

export const BaseLayout = ({
  pageName,
  children,
  title,
  documentTitle,
  toolbox,
  actions,
  navigation,
  searchbar,
  planFamily,
  hideNavigation,
  maxWidth = 'lg',
  forceShowInstanceHeader = false,
  fullScreen = false,
}: BaseLayoutProps): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();

  const [showFullMobileMenu, setShowFullMobileMenu] = useState(false);
  const [resetFn, setResetFn] = useState<(() => void) | null>(null);
  const mdAndAbove = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (documentTitle) {
      document.title = `${documentTitle} - LocalStack`;
    } else if (title && typeof title === 'string') {
      document.title = `${title} - LocalStack`;
    } else {
      document.title = 'LocalStack';
    }
  }, [title, documentTitle]);

  const selectedInstance = getSelectedInstance();

  const isResourceBrowser = !!useMatch(AppRoute.RESOURCES_COMMON);
  const isSystemStatus = !!useMatch(AppRoute.STATUS);
  const isStateManage = !!useMatch(AppRoute.INSTANCE_STATE);
  const isExtensionsManager = !!useMatch(AppRoute.EXTENSIONS_MANAGE);
  const isLogsViewer = !!useMatch(AppRoute.INSTANCE_LOGS);
  const showInstanceHeader =
    forceShowInstanceHeader || isResourceBrowser || isSystemStatus || 
    isExtensionsManager || isStateManage || isLogsViewer;

  const isMobile = !mdAndAbove;
  const isCollapsed = isMobile && !showFullMobileMenu;
  const isExpanded = isMobile && showFullMobileMenu;
  const containerMaxWidth = fullScreen ? false : maxWidth;

  const { goto } = useRoutes();
  const { showSnackbar } = useSnackbar();

  const { instances, setInstances } = useContext(GlobalStateContext);

  const { deleteEphemeralInstance, isLoading: isDeletingInstance } = useApiEffect(
    ComputeService,
    ['deleteEphemeralInstance'],
    { revalidate: ['listEphemeralInstances'] },
  );

  const onRemove = async (instance: LocalStackInstance) => {
    if (instance.ephemeralInstance === undefined) {
      setInstances(instances.filter((i) => i.id !== instance.id));
      goto(AppRoute.INSTANCES);
      showSnackbar({
        message: 'Successfully removed instance',
        severity: 'success',
      });
    } else {
      const res = await deleteEphemeralInstance(instance.id);
      if (res) {
        showSnackbar({
          message: 'Successfully removed instance',
          severity: 'success',
        });  
        goto(AppRoute.INSTANCES_EPHEMERAL);
      }
    }
  };

  return (
    <div
      data-page-name={pageName}
      data-name="page container"
      className={classNames(classes.root, classes.pageContainer)}
    >
      {!hideNavigation && (
        <div data-name="navigation" className={classNames(classes.sidebar, { floating: isExpanded })}>
          <div data-name="sidebar" className={classNames({ collapsed: isCollapsed })}>
            {/* burger */}
            {isMobile && (
              <div data-name="burger container" className={classes.burgerContainer}>
                {!showFullMobileMenu ? (
                  // burger
                  <IconButton onClick={() => setShowFullMobileMenu(true)} size="large">
                    <MenuIcon />
                  </IconButton>
                ) : (
                  // close button
                  <IconButton onClick={() => setShowFullMobileMenu(false)} size="large">
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            )}

            <WorkspaceSelector />

            {toolbox}
            {navigation}
          </div>
        </div>
      )}

      <div data-name="page content" className={classes.pageContent}>
        <div data-name="content preface">
          {!hideNavigation && (
            <>
              <PlanUpgradeBanner />
              <NewsBanner />
            </>
          )}
        </div>
        <div data-name="main content" className={classes.mainContent}>
          {/* Instance Header */}
          {showInstanceHeader && !fullScreen && (
            <LocalStackInstanceHeader
              instance={selectedInstance}
              isRemovingInstance={isDeletingInstance}
              onRemove={onRemove}
            />
          )}
          {(title || actions) && (
            <div data-name="in-page navigation and actions" className={classes.nav}>
              {/* Title and actions */}
              <Container maxWidth={containerMaxWidth}>
                <Box display="flex" justifyContent="space-between" alignItems="center" justifyItems="right">
                  {typeof title === 'string' ? (
                    <Typography variant="h4">
                      {title}
                      {planFamily && <PlanChip planFamily={planFamily} showTooltip />}
                    </Typography>
                  ) : (
                    title
                  )}
                  <div className={classes.actions}>
                    {isResourceBrowser && resetFn && <Button onClick={() => resetFn()}>Reset Form</Button>}
                    {actions}
                    {isResourceBrowser && (
                      <>
                        {actions && <div className={classes.regionDivider} />}
                        <TestMarkerSpan name={MARKER_IDS.REGION_DROPDOWN}>
                          <AwsRegionDropdown />
                        </TestMarkerSpan>
                        <AwsAccountField />
                      </>
                    )}
                    {searchbar && (
                      <>
                        {actions && <div className={classes.regionDivider} />}
                        <Box ml={3} flexGrow={1}>
                          {searchbar}
                        </Box>
                      </>
                    )}
                  </div>
                </Box>
              </Container>
            </div>
          )}
          {/* MAIN */}
          <BaseLayoutContext.Provider value={{ setResetFn }}>{children}</BaseLayoutContext.Provider>
        </div>

        <footer data-name="footer" className={classes.footer}>
          <Container maxWidth={containerMaxWidth}>
            <Typography variant="subtitle2" component="div">
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                style={{ columnGap: '1em', rowGap: '1.5em', flexWrap: 'wrap' }}
                py={2}
                px={1}
              >
                <Box display="flex" style={{ columnGap: '1em', rowGap: '1.5em' }}>
                  <Link href={ExternalLink.WEBSITE_BASE} target="_blank" underline="hover">
                    Website
                  </Link>
                  <Box component="span">
                    <Link href={ExternalLink.WEBSITE_BLOG} target="_blank" underline="hover">
                      Blog
                    </Link>
                  </Box>
                  <Box component="span">
                    <Link href={ExternalLink.DOCS_BASE} target="_blank" underline="hover">
                      Docs
                    </Link>
                  </Box>
                  <Box component="span">
                    <Link href={ExternalLink.DISCUSS} target="_blank" underline="hover">
                      Discuss
                    </Link>
                  </Box>
                </Box>

                <Box display="flex" style={{ columnGap: '1em', rowGap: '1.5em', flexWrap: 'wrap' }}>
                  <Box component="span">
                    <Link href={ExternalLink.WEBSITE_CONTACT} target="_blank" underline="hover">
                      Contact
                    </Link>
                  </Box>
                  <Box component="span">
                    <Link href={ExternalLink.LEGAL_TOS} target="_blank" underline="hover">
                      Terms and Conditions
                    </Link>
                  </Box>
                  <Box component="span">
                    <Link href={ExternalLink.LEGAL_PRIVACY_POLICY} target="_blank" underline="hover">
                      Privacy Policy
                    </Link>
                  </Box>
                  <Box component="span" whiteSpace="nowrap" style={{ color: theme.typography.caption.color }}>
                    &copy; LocalStack 2017-{new Date().getFullYear()}
                  </Box>
                </Box>
              </Box>
            </Typography>
          </Container>
        </footer>
      </div>
    </div>
  );
};
