import { ReactElement, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CardHeader, CardContent, Box, FormControlLabel,
  Typography, Checkbox, Grid, Link,
} from '@mui/material';
import classnames from 'classnames';
import { ExternalLink } from '@localstack/constants';
import { VALIDATION_RULES } from '@localstack/services';
import {
  ControlledTextField, DecorativeDivider, ControlledCheckbox,
  TosAgreementLine, TOS_REQUIRED_ERROR,
  NewsletterSubscriptionLine,
} from '@localstack/ui';
import { PlannedUsageType } from '@localstack/types';

import { useStyles } from './styles';
import { ApiError } from './ApiError';
import { AxiosErrorType, SIGNUP_FLOW_TYPE } from './common-types';

type Props = {
  error: AxiosErrorType;
  flowType: SIGNUP_FLOW_TYPE;
}

export const PersonalDetails = ({ error, flowType }: Props): ReactElement => {
  const { control, watch, formState, trigger } = useFormContext();
  const classes = useStyles();

  const plannedUsageType = watch('plannedUsageType');
  const createSub = watch('createSub');
  const isSSOFlow = [SIGNUP_FLOW_TYPE.PUBLIC_SSO, SIGNUP_FLOW_TYPE.ENTERPRISE_SSO].includes(flowType);

  useEffect(() => {
    if (plannedUsageType === PlannedUsageType.HOBBYIST) {
      trigger('hobbyTermsAgree');
    }
  }, [createSub]);

  return (
    <>
      <CardHeader title="Finish account creation" />
      <CardContent>
        <ApiError error={error} />
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Grid container spacing={2}>
              {isSSOFlow &&
                <Grid item xs={12}>
                  <ControlledTextField
                    control={control}
                    name="email"
                    fullWidth
                    required
                    label="Email address"
                    type="text"
                    variant="outlined"
                    disabled
                  />
                </Grid>}
              <Grid item xs={12} lg={6}>
                <ControlledTextField
                  control={control}
                  name="firstname"
                  fullWidth
                  required
                  label="First Name"
                  type="text"
                  variant="outlined"
                  rules={VALIDATION_RULES.required}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ControlledTextField
                  control={control}
                  name="lastname"
                  fullWidth
                  required
                  label="Last Name"
                  type="text"
                  variant="outlined"
                  rules={VALIDATION_RULES.required}
                />
              </Grid>
              {plannedUsageType === PlannedUsageType.PROFESSIONAL &&
                <>
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={control}
                      name="company"
                      fullWidth
                      label="Company"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={control}
                      name="job_title"
                      fullWidth
                      label="Job Title"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                </>}
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="github_username"
                  fullWidth
                  label="Github Username"
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box my={1}>
              <DecorativeDivider />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {(plannedUsageType === PlannedUsageType.PROFESSIONAL && flowType !== SIGNUP_FLOW_TYPE.ENTERPRISE_SSO) && <>
              <Controller
                name='createSub'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    className={classnames(classes.primaryItemBox, classes.primaryItemBoxActive)}
                    control={<Checkbox size='large' {...field} />} label={
                      <>
                        <Typography variant="body1">Start 14 day free trial</Typography>
                        <Typography variant="caption">
                          Access all advanced features of LocalStack.
                        </Typography>
                      </>
                    }
                  />
                )}
              />
            </>}
            {plannedUsageType === PlannedUsageType.HOBBYIST &&
              <div className={classnames(classes.primaryItemBox, classes.primaryItemBoxActive)}>
                <Controller
                  name='createSub'
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      control={<Checkbox size='large' {...field} />} label={
                        <Typography variant="body1" display="block">Get Hobby License</Typography>
                      }
                    />
                  )}
                />
                <Box className={classes.hobbyBox} mb={1}>
                  <Typography variant="caption" display="block" mt={1}>
                    We are offering a special “Hobby” plan for users who are using {' '}
                    LocalStack only non-commercially.
                  </Typography>
                  <Typography variant="caption" display="block" mt={1}>
                    LocalStack&apos;s Hobby plan is explicitly for personal, non-corporate and{' '}
                    non-commercial use only. Any commercial use is in direct violation of our{' '}
                    Terms and Conditions. If you are unsure whether you qualify, please refer {' '}
                    to our FAQ or reach out to us directly.
                  </Typography>
                  <Typography variant="caption" display="block" mt={1} mb={2}>
                    Usage of the hobby plan is subject to periodic review.
                  </Typography>
                  <Controller
                    name='hobbyTermsAgree'
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value && plannedUsageType === PlannedUsageType.HOBBYIST && createSub) {
                          return 'Please accept terms and conditions';
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <FormControlLabel
                        sx={{ width: '100%' }}
                        control={<Checkbox size='small' {...field} />}
                        label={
                          <>
                            <Typography variant="body2" color='textSecondary'>
                              In checking this box I declare my usage under this account to{' '}
                              be non-commercial and agree to the Terms and Conditions located{' '}
                              <Link target='_blank'
                                href={ExternalLink.LEGAL_TOS} underline="hover"
                              >here</Link>.
                            </Typography>
                          </>
                        }
                      />
                    )}
                  />
                  {formState.errors.hobbyTermsAgree &&
                    <Typography fontSize='12' color='error.main'>
                      {formState.errors.hobbyTermsAgree.message}
                    </Typography>}
                </Box>
              </div>
            }
          </Grid>
          <Grid item xs={12}>
            <ControlledCheckbox
              label={
                <Typography variant="body1" color="textSecondary" display="inline">
                  <TosAgreementLine />
                </Typography>
              }
              name="tos"
              control={control}
              required
              rules={{
                required: TOS_REQUIRED_ERROR,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCheckbox
              label={<NewsletterSubscriptionLine />}
              name="newsletter"
              control={control}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
