import {
  CancellationFeedback,
  ExtensionSubmissionState,
  MembershipRole,
  Permission,
  PlanFamily,
  SubscriptionStatus,
} from '@localstack/types';

export * from './audit';
export * from './routes';
export * from './services';
export * from './countries';
export * from './OAuthAttributes';
export * from './colors';
export * from './time';
export * from './errors';
export * from './global';
export * from './serviceCodes';
export * from './links';
export * from './values';
export * from './compute';

export const STORAGE_KEY_IMPERSONATION_MARKER = 'impersonationMarker';
export const STORAGE_KEY_AUTH_TOKEN = 'authToken';
export const STORAGE_KEY_USER_INFO = 'userInfoV2'; // note v2 here to discard old data
export const STORAGE_KEY_SURVEYS = 'surveys';
export const STORAGE_KEY_DASHBOARD_LAYOUT = 'dashboard_layout'; // do not clean
export const STORAGE_KEY_FAVORITES = 'favorites'; // do not clean
export const STORAGE_KEY_BETA_FEATURES = 'beta'; // do not clean
export const STORAGE_KEY_RESOURCE_BASE_URL = 'base_url_https'; // do not clean
export const STORAGE_KEY_INSTANCES = 'instances_v0'; // do not clean
export const STORAGE_KEY_REGION = 'region';
export const STORAGE_KEY_AWS_ACCOUNT_ID = 'aws_account_id';
export const STORAGE_KEY_IGNORE_EXPIRED_TRIAL = 'ignore_expired_trial';
export const STORAGE_KEY_PRE_SIGNIN_PAGE_URL = 'pre_sign_in_page_url';
export const STORAGE_KEY_NOTIFICATIONS_ENABLED = 'notifications_enabled';
export const STORAGE_KEY_NEWS_BANNER = 'news_banner';
export const STORAGE_KEY_HUBSPOT_TOKEN = 'hubspot_token_data';

export const STORAGE_KEYS = [
  STORAGE_KEY_USER_INFO,
  STORAGE_KEY_AUTH_TOKEN,
  STORAGE_KEY_IMPERSONATION_MARKER,
  STORAGE_KEY_REGION,
  STORAGE_KEY_HUBSPOT_TOKEN,
  STORAGE_KEY_PRE_SIGNIN_PAGE_URL,
];

export const INSTANCE_DEFAULT_ID = 'default';

export const STORAGE_EPHEMERAL_INSTANCE_TTL_SECONDS = 60 * 60;

export const DEFAULT_REGION = 'us-east-1';
export const DEFAULT_AWS_ACCOUNT_ID = '000000000000';
export const DEFAULT_RESOURCE_BASE_URL = 'https://localhost.localstack.cloud:4566';

export const BASE_PATH = process.env.BASE_PATH ?? '/';

export const TRUE_VALUES = [true, 'true', 'True', '1', 1];

export const AWS_REGIONS = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'ca-central-1',
  'ca-west-1',
  'eu-north-1',
  'eu-west-3',
  'eu-west-2',
  'eu-west-1',
  'eu-central-1',
  'eu-south-1',
  'ap-south-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-southeast-3',
  'ap-southeast-4',
  'ap-east-1',
  'sa-east-1',
  'cn-north-1',
  'cn-northwest-1',
  'us-gov-east-1',
  'us-gov-west-1',
  'me-south-1',
  'af-south-1',
  'me-central-1',
  'eu-south-2',
  'eu-central-2',
  'ap-south-2',
  'il-central-1',
].sort();

export const CANCELLATION_REASONS: { key: '' | CancellationFeedback, text: string }[] = [
  { key: '', text: 'Please select the reason for cancellation ...' },
  {
    key: CancellationFeedback.TOO_EXPENSIVE,
    text: 'Too expensive - We currently lack the budget to pay for our licenses',
  },
  {
    key: CancellationFeedback.MISSING_FEATURES,
    text: 'Features missing - Some APIs that we require are not yet fully supported',
  },
  {
    key: CancellationFeedback.SWITCHED_SERVICE,
    text: 'Switching service - We are switching to a different provider',
  },
  {
    key: CancellationFeedback.UNUSED,
    text: 'Not needed - Our requirements have changed and we don\'t need LocalStack anymore',
  },
  {
    key: CancellationFeedback.CUSTOMER_SERVICE,
    text: 'Customer service - Customer service did not meet our expectations',
  },
  {
    key: CancellationFeedback.TOO_COMPLEX,
    text: 'Too complex - LocalStack was too hard to work with',
  },
  {
    key: CancellationFeedback.LOW_QUALITY,
    text: 'Stability - LocalStack diverges too much from AWS to support our use cases',
  },
  {
    key: CancellationFeedback.OTHER,
    text: 'Other - Please specify',
  },
];

export const SUB_ACTIVE_STATUSES = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.TRIALING,
  SubscriptionStatus.PAST_DUE,
];

export const SUB_ACTIVE_PAID_STATUSES = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.PAST_DUE,
];

export const SUB_CANCELED_STATUSES = [
  SubscriptionStatus.CANCELED,
  SubscriptionStatus.UNPAID,
];

export const ROLE_MAPPINGS = {
  [MembershipRole.ADMIN]: [
    Permission.READ_CREDIT_CARDS,
    Permission.CREATE_CREDIT_CARDS,
    Permission.READ_SUBSCRIPTIONS,
    Permission.CREATE_SUBSCRIPTIONS,
    Permission.UPDATE_SUBSCRIPTIONS,
    Permission.DELETE_SUBSCRIPTIONS,
    Permission.READ_ORG_KEYS,
    Permission.UPDATE_ORG_KEYS,
    Permission.CREATE_ORG_KEYS,
    Permission.CREATE_SELF_KEYS,
    Permission.DELETE_ORG_KEYS,
    Permission.READ_MEMBERS,
    Permission.CREATE_MEMBERS,
    Permission.UPDATE_MEMBERS,
    Permission.DELETE_MEMBERS,
    Permission.READ_INVOICES,
    Permission.UPDATE_ORGANIZATION,
    Permission.LIST_LICENSES,
    Permission.ASSIGN_LICENSES,
    Permission.UNASSIGN_LICENSES,
    Permission.READ_CI_KEYS,
    Permission.WRITE_CI_KEYS,
  ],
  [MembershipRole.MEMBER]: [
  ],
};

export const PERMISSION_TYPES = {
  [Permission.READ_CREDIT_CARDS]: 'View Payment Methods',
  [Permission.CREATE_CREDIT_CARDS]: 'Add new Payment Methods',
  [Permission.READ_SUBSCRIPTIONS]: 'View Organization Subscriptions',
  [Permission.CREATE_SUBSCRIPTIONS]: 'Subscribe Organization to new Plans',
  [Permission.UPDATE_SUBSCRIPTIONS]: 'Update details of existing Subscriptions',
  [Permission.DELETE_SUBSCRIPTIONS]: 'Cancel Organization Subscriptions',
  [Permission.READ_ORG_KEYS]: 'View all API Keys owned by the Organization',
  [Permission.UPDATE_ORG_KEYS]: 'Update API Keys owned by the Organization',
  [Permission.CREATE_ORG_KEYS]: 'Issue any API Keys for the Organization',
  [Permission.CREATE_SELF_KEYS]: 'Allow member to issue a license for themselves (or a legacy API key)',
  [Permission.DELETE_ORG_KEYS]: 'Delete organization API Keys',
  [Permission.READ_MEMBERS]: 'View Team Member Details',
  [Permission.CREATE_MEMBERS]: 'Invite new Team Members',
  [Permission.UPDATE_MEMBERS]: 'Update Team Members',
  [Permission.DELETE_MEMBERS]: 'Delete Team Members',
  [Permission.READ_INVOICES]: 'Access to Organization Invoices',
  [Permission.UPDATE_ORGANIZATION]: 'Update organization Details',
  [Permission.LIST_LICENSES]: 'View all licenses within the Organization',
  [Permission.ASSIGN_LICENSES]: 'Assign licenses to team members within the Organization',
  [Permission.UNASSIGN_LICENSES]: 'Unassign licenses from team members within the Organization',
  [Permission.READ_CI_KEYS]: 'View CI keys',
  [Permission.WRITE_CI_KEYS]: 'Manage CI keys',
};

export const PERMISSION_GROUPS = {
  Organization: [
    Permission.UPDATE_ORGANIZATION,
    Permission.READ_MEMBERS,
    Permission.CREATE_MEMBERS,
    Permission.UPDATE_MEMBERS,
    Permission.DELETE_MEMBERS,
  ],
  'Payment Details': [Permission.READ_CREDIT_CARDS, Permission.CREATE_CREDIT_CARDS, Permission.READ_INVOICES],
  Subscriptions: [
    Permission.READ_SUBSCRIPTIONS,
    Permission.CREATE_SUBSCRIPTIONS,
    Permission.UPDATE_SUBSCRIPTIONS,
    Permission.DELETE_SUBSCRIPTIONS,
  ],
  'API Keys (Deprecated)': [
    Permission.READ_ORG_KEYS,
    Permission.CREATE_ORG_KEYS,
    Permission.CREATE_SELF_KEYS,
    Permission.UPDATE_ORG_KEYS,
    Permission.DELETE_ORG_KEYS,
  ],
  'CI Keys': [
    Permission.READ_CI_KEYS,
    Permission.WRITE_CI_KEYS,
  ],
  'Licenses': [
    Permission.LIST_LICENSES,
    Permission.ASSIGN_LICENSES,
    Permission.UNASSIGN_LICENSES,
  ],
};

// prettier-ignore
export const EU_COUNTRIES = [
  'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE',
  'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV',
  'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK',
  'SI', 'ES', 'SE',
  'XI', // Nothern Ireland is no longer in EU, but Stripe's Tax system still thinks it is
];

/**
 * Global SWR cache keys
 */

export enum SwrCacheKey {
  HEALTH = 'health',
  EVENT_STATS = 'eventStats',
  STACK_INFO = 'stackInfo',
}

export enum FeatureFlag {
  RESOURCE_BROWSER_V2 = 'RESOURCE_BROWSER_V2',
}

export const PublicAsset = {
  DEMO_LAMBDA: `${BASE_PATH}demo1/lambda.zip`,
};

export enum ThemeType {
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM = 'system',
}

export const EXTENSION_ADMIN_ORDER = [
  ExtensionSubmissionState.SUBMITTED,
  ExtensionSubmissionState.REJECTED,
  ExtensionSubmissionState.APPROVED,
  ExtensionSubmissionState.PUBLISHED,
];

export const PRICELESS_PLAN_FAMILIES = [PlanFamily.TRIAL_PLANS, PlanFamily.ENTERPRISE_PLANS];
export const LISTABLE_PLAN_FAMILIES = [
  PlanFamily.TRIAL_PLANS,
  PlanFamily.PRO_PLANS,
  PlanFamily.TEAM_PLANS,
  PlanFamily.ENTERPRISE_PLANS,
];
export const SUBSCRIBABLE_PLAN_FAMILIES = [
  PlanFamily.TRIAL_PLANS,
  PlanFamily.HOBBY_PLANS,
  PlanFamily.PRO_PLANS,
  PlanFamily.TEAM_PLANS,
];
export const UPDATEABLE_PLAN_FAMILIES = [
  PlanFamily.TRIAL_PLANS,
  PlanFamily.HOBBY_PLANS,
  PlanFamily.PRO_PLANS,
  PlanFamily.TEAM_PLANS,
  PlanFamily.LEGACY_PLANS,
];
export const CANCELABLE_PLAN_FAMILIES = [
  PlanFamily.TRIAL_PLANS,
  PlanFamily.HOBBY_PLANS,
  PlanFamily.PRO_PLANS,
  PlanFamily.TEAM_PLANS,
  PlanFamily.LEGACY_PLANS,
];

// export const PUBLIC_IDENTITY_PROVIDERS = ['github', 'google'];
export const PUBLIC_IDENTITY_PROVIDERS = ['github'];

export const VAT_COUNTRIES = ['CH'];

// list of regexes that matches sub-service which are pro but part of non-pro services
export const PRO_REGEXES = [
  /\/resources\/apigateway\/[^/]+\/authorizers/,
] as RegExp[];
