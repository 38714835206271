import { useState, useCallback, useEffect, ReactElement } from 'react';
import {
  useRoutes,
  useAwsEffect,
  useAwsGetter,
  useAwsKeysMemo,
} from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, IAMPoliciesTable, PageTitle } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

const PAGE_SIZE = 100;

export const IAMPolicies = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [paginationKey, setPaginationKey] = useState<string | undefined>(undefined);

  const { goto } = useRoutes();

  const { data: policies, isLoading, mutate } = useAwsGetter(
    'IAM',
    'listPolicies',
    [{ MaxItems: PAGE_SIZE, Marker: paginationKey }],
    { clientOverrides, defaultValue: { Policies: [], IsTruncated: false, Marker: undefined } },

  );

  const { deletePolicy } = useAwsEffect(
    'IAM',
    ['deletePolicy'],
    { revalidate: ['getPolicy', 'listPolicies'], clientOverrides },
  );

  const queryKeys = useAwsKeysMemo(page, policies?.Marker);

  useEffect(() => {
    setPaginationKey(queryKeys[page]);
  }, [page]);

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((PolicyArn) => deletePolicy({ PolicyArn }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={
        <PageTitle
          title="IAM Policies"
          onMutate={mutate}
        />
      }
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_IAM_POLICY_CREATE)}>
            Create Policy
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Policy(es)?`}
              onClick={handleDeleteSelected}
              text="Selected Policies will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card style={{ height: '100%' }}>
        <IAMPoliciesTable
          selectable
          loading={isLoading}
          policies={policies?.Policies ?? []}
          hasMore={policies?.IsTruncated ?? true}
          onSelect={setSelectedIds}
          page={page}
          onPageChange={setPage}
          pageSize={PAGE_SIZE}
          onViewPolicy={
            ({ Arn }) => goto(routes.RESOURCES_IAM_POLICY, { policyArn: encodeURIComponent(Arn || '') })
          }
        />
      </Card>
    </Layout >
  );
};
