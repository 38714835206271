import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Material helpers
import { Theme } from '@mui/material/styles';

import { GlobalStateProvider } from '@localstack/ui';

import { LocalStackThemeProvider } from '@localstack/integrations';

import { configureErrorsTracker, configureApiClient } from '~/config';

// Service worker
import * as serviceWorker from '~/config/serviceWorker';

import App from './App';

import { SurveyProvider, AuthGuardProvider } from './components';

// grid-layout and resizable
import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';

// our own
import './assets/index.css';
import { HubspotProvider } from './components/HubspotProvider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

configureErrorsTracker();
configureApiClient();

ReactDOM.render(
  <LocalStackThemeProvider disableCommunicationProvider>
    <BrowserRouter>
      <GlobalStateProvider>
        <AuthGuardProvider>
          <HubspotProvider>
            <SurveyProvider />
            <App />
          </HubspotProvider>
        </AuthGuardProvider>
      </GlobalStateProvider>
    </BrowserRouter>
  </LocalStackThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
