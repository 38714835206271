import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
} from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';


import { useNavigate } from 'react-router-dom';

import {
  getPreviewState,
  getNotificationSettings,
  updatePreviewState,
  updateNotificationSettings,
} from '~/util/storage';

export const Settings = (): ReactElement => {
  const [previewState, setPreviewState] = useState(getPreviewState());

  const navigate = useNavigate();
  const notificationsEnabled = getNotificationSettings();

  useEffect(() => {
    updatePreviewState(previewState);
  }, [previewState]);

  const changeNotificationSettings = (enabled: boolean) => {
    updateNotificationSettings(enabled);
    navigate(0);
  };

  return (
    <Card>
      <CardHeader title="Settings" />
      <CardContent>
        <List>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notificationsEnabled}
                  onChange={(_, checked) => changeNotificationSettings(checked)}
                  color="primary"
                />
              }
              label="Show Notifications in the Web Application"
            />
          </ListItem>

          <ListItem style={{ display: 'none' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={previewState}
                  onChange={(_, checked) => setPreviewState(checked)}
                  color="primary"
                />
              }
              label="Enable Preview Features"
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};
