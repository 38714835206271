import { ReactElement } from 'react';
import { Card, CardHeader, CardMedia, CardActions, Link } from '@mui/material';
import { AdminWidget } from '@localstack/types';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { SERVICE_BASE_URL } from '~/config';

export interface DashboardWidget {
  title: string;
  widget: AdminWidget;
  authToken: string;
}

const useStyles = makeStyles(() => createStyles({
  widget: {
    width: '100%',
    height: 'auto',
  },
}));

export const DashboardWidget = ({ title, widget, authToken }: DashboardWidget): ReactElement => {
  const classes = useStyles();

  const getWidgetUrl = (widgetId: string) =>
    `${SERVICE_BASE_URL}/v1/admin/widgets/${widgetId}?authorization=${authToken}`;

  return (
    <Card>
      <CardHeader title={title} />
      <CardMedia>
        <img className={classes.widget} alt={widget} src={getWidgetUrl(widget)} />
      </CardMedia>
      <CardActions>
        <Link href={getWidgetUrl(widget)} target="_blank" underline="hover">Details</Link>
      </CardActions>
    </Card>
  );
};
