import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Checkbox,
  FormControlLabel,
  Typography,
  ListItemSecondaryAction,
  Divider,
  Alert } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Archive, ArrowForward, Check, NotificationsNone, Settings } from '@mui/icons-material';

import { useApiEffect, useApiGetter, useRoutes, UserService } from '@localstack/services';

import { LoadingFragment, ProgressButton } from '@localstack/ui';
import { Notification } from '@localstack/types';


import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import { NotificationModal } from './NotificationModal';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    cursor: 'pointer',
  },
  filterSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: 8,
    '& *': {
      color: theme.palette.primary.contrastText,
    },
  },
  notificationList: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  notificationHeader: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  notificationItem: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

enum Filter {
  INBOX = 'inbox',
  DONE = 'done',
  ALL = 'all',
}

const filterMapping = {
  [Filter.INBOX]: (notification: Notification) => !notification.read,
  [Filter.DONE]: (notification: Notification) => notification.read,
  [Filter.ALL]: () => true,
};

type FilterItem = {
  filter: Filter;
  icon: ReactElement;
  text: string;
}

const filterItems: FilterItem[] = [
  { filter: Filter.INBOX, icon: <NotificationsNone />, text: 'Inbox' },
  { filter: Filter.DONE, icon: <Check />, text: 'Done' },
  { filter: Filter.ALL, icon: <Archive />, text: 'All' },
];

export const Notifications = (): ReactElement => {
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState<Filter>(Filter.INBOX);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState<Notification[]>([]);
  const [openNotification, setOpenNotification] = useState<Notification>();

  const { goto } = useRoutes();

  const { data: notifications, isLoading: isNotificationsLoading } =
    useApiGetter(UserService, 'listNotifications', []);
  const { updateNotification, isLoading: isNotificationMutating } =
    useApiEffect(UserService, ['updateNotification'], { revalidate: ['listNotifications'] });

  const displayedNotifications = useMemo(
    () => notifications?.filter(filterMapping[selectedFilter]).sort((a, b) => (b.created ?? 0) - (a.created ?? 0)),
    [notifications, selectedFilter],
  );

  const onCheck = useCallback(
    (notification: Notification) => {
      if (selectedNotifications.filter(n => n.id === notification.id).length > 0) {
        setSelectedNotifications(selectedNotifications.filter(n => n.id !== notification.id));
      } else {
        setSelectedNotifications([...selectedNotifications, notification]);
      }
    },
    [selectedNotifications],
  );

  useEffect(() => {
    if (selectAll) {
      setSelectedNotifications(displayedNotifications || []);
    } else {
      setSelectedNotifications([]);
    }
  }, [selectAll]);

  const onDone = useCallback((read: boolean) => {
    selectedNotifications.forEach(notification => {
      updateNotification(notification.id, { ...notification, read });
    });
    setSelectedNotifications([]);
    setSelectAll(false);
  }, [selectedNotifications]);

  useEffect(() => {
    setSelectAll(false);
  }, [selectedFilter]);


  return (
    <CustomerLayout title="Notifications">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <List disablePadding>
            {filterItems.map(({ filter, icon, text }) => (
              <ListItem
                className={classes.listItem}
                classes={{ selected: classes.filterSelected }}
                selected={selectedFilter === filter}
                onClick={() => setSelectedFilter(filter)}
              >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
            <Divider style={{ margin: '1rem' }} />
            <ListItem
              className={classes.listItem}
              onClick={() => goto(AppRoute.ACCOUNT_SETTINGS)}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={9}>
          <List className={classes.notificationList} disablePadding>
            <ListItem className={classes.notificationHeader}>
              <ListItemIcon onClick={() => setSelectAll(!selectAll)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={selectAll}
                      tabIndex={-1}
                      disableRipple
                    />
                  }
                  label="Select all"
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { display: 'flex', alignItems: 'center', gap: '0.5rem' } }}
                primary={
                  selectedNotifications.length > 0 &&
                  <>
                    <Typography>
                      {selectedNotifications.length} selected
                    </Typography>
                    {selectedFilter !== Filter.DONE &&
                      <ProgressButton
                        style={{ textTransform: 'none' }}
                        size='small'
                        variant="contained"
                        color="primary"
                        startIcon={<Check />}
                        onClick={() => onDone(true)}
                        loading={isNotificationMutating}
                      >
                        Mark as Done
                      </ProgressButton>
                    }
                    {selectedFilter !== Filter.INBOX &&
                      <ProgressButton
                        style={{ textTransform: 'none' }}
                        size='small'
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowForward />}
                        onClick={() => onDone(false)}
                        loading={isNotificationMutating}
                      >
                        Move to Inbox
                      </ProgressButton>
                    }
                  </>
                }
              />
            </ListItem>
            <LoadingFragment loading={isNotificationsLoading} variant='list' size={3} height={57}>
              {displayedNotifications?.map((notification: Notification) =>
                <ListItem
                  button
                  onClick={
                    () => setOpenNotification(openNotification?.id === notification.id ? undefined : notification)
                  }
                  key={notification.id}
                  className={classes.notificationItem}
                >
                  <ListItemIcon onClick={
                    (event: React.MouseEvent<HTMLInputElement>) => {
                      onCheck(notification);
                      event?.stopPropagation();
                    }
                  }
                  >
                    <Checkbox
                      color='primary'
                      edge="start"
                      checked={selectedNotifications.filter(n => n.id === notification.id).length > 0}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={notification.subject} />
                  <ListItemSecondaryAction >
                    {notification.created &&
                      <Typography variant='body2'>
                        {moment(notification.created * 1000).fromNow()}
                      </Typography>
                    }
                  </ListItemSecondaryAction>
                </ListItem>,
              )}
              {displayedNotifications?.length === 0 &&
                <ListItem>
                  <Alert style={{ width: '100%' }} severity="info" variant="outlined">No notifications found</Alert>
                </ListItem>
              }
            </LoadingFragment>
            <NotificationModal
              notification={openNotification}
              onClose={() => setOpenNotification(undefined)}
            />
          </List>
        </Grid>
      </Grid>
    </CustomerLayout>
  );
};
