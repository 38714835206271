import { ChangeEvent, ReactElement, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  OrganizationsService,
  VALIDATION_RULES,
  getTaxIdExample,
  useApiEffect,
} from '@localstack/services';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Switch,
  Typography,
} from '@mui/material';

import {
  ControlledCountryAutocomplete,
  ControlledTextField,
  ProgressButton,
  TaxIdTextField,
} from '@localstack/ui';


import { Organization, OrganizationType, Permission } from '@localstack/types';

import { useAuthProvider } from '~/hooks';
import { CustomerLayout } from '~/layouts';

export const WorkspaceSettings = (): ReactElement => {
  const { can } = useAuthProvider();

  const { updateOrganization, isLoading: isOrgMutating } = useApiEffect(
    OrganizationsService,
    ['updateOrganization'],
    { revalidate: ['listOrganizations'] },
  );

  const isOrgLoading = isOrgMutating;

  const { userInfo } = useAuthProvider();
  const organization = userInfo?.org;

  const orgForm = useForm<{ org: Organization }>({ mode: 'all' });

  const organizationValues = orgForm.watch('org');
  const { country, tax_id: taxId } = organizationValues || {};

  const isCompany = organization?.orgType === OrganizationType.COMPANY;

  const onSubmitOrgInfo = useCallback(
    async (data: { org: Partial<Organization> }) => {

      if (organization && can(Permission.UPDATE_ORGANIZATION)) {
        const orgUpdates: Partial<Organization> = Object.keys(orgForm.formState.dirtyFields.org || {}).reduce(
          (memo, field) => ({ ...memo, [field]: data.org[field as keyof typeof data.org] }), {},
        );

        await updateOrganization(organization.id, orgUpdates as Organization);
      }
    }, [orgForm.formState, organization, can],
  );

  // Set initial form values
  useEffect(() => {
    if (organization) {
      orgForm.reset({ org: organization });
    }
  }, [organization]);

  const orgToggleOnChange = (_: ChangeEvent, checked: boolean) => {
    if (!organization?.id) {
      return;
    }

    updateOrganization(organization.id, {
      name: (organization?.name) ?? '',
      orgType: checked ? OrganizationType.COMPANY : OrganizationType.INDIVIDUAL,
    } as Organization);
  };

  return (
    <CustomerLayout title="Workspace Settings">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* organzation info */}
          <form onSubmit={orgForm.handleSubmit(onSubmitOrgInfo)}>
            <Card style={{ marginTop: '1em' }}>
              <CardHeader title='Workspace Settings' />
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={orgForm.control}
                      name="org.name"
                      label={isCompany ? 'Organization/Workspace Name' : 'Workspace Name'}
                      placeholder={isCompany ? 'Organization/Workspace Name' : 'Workspace Name'}
                      fullWidth
                      required
                      variant="outlined"
                      rules={VALIDATION_RULES.required}
                      disabled={isOrgLoading || !can(Permission.UPDATE_ORGANIZATION)}
                    />
                  </Grid>

                  {can(Permission.UPDATE_ORGANIZATION) && (
                    <>
                      {/* company toggle */}
                      <Grid xs={12} paddingX='8px'>
                        <Box pt={3}>
                          <Switch
                            checked={isCompany}
                            onChange={orgToggleOnChange}
                            disabled={isOrgLoading}
                            color="primary"
                          />
                          <Typography variant="caption">
                            Company Account
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <ControlledCountryAutocomplete
                          control={orgForm.control}
                          name="org.country"
                          rules={VALIDATION_RULES.required}
                          inputProps={{
                            label: 'Country',
                            placeholder: 'Country',
                            fullWidth: true,
                            required: true,
                            variant: 'outlined',
                            disabled: !isCompany,
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TaxIdTextField
                          control={orgForm.control}
                          name="org.tax_id"
                          label="TAX ID"
                          placeholder={getTaxIdExample(country) || ''}
                          fullWidth
                          variant="outlined"
                          rules={VALIDATION_RULES.taxId(country)}
                          taxId={taxId}
                          country={country}
                          onChangeTaxType={(newTaxType) => orgForm.setValue('org', {
                            ...organizationValues,
                            tax_id_type: newTaxType || undefined,
                          }, { shouldDirty: true })}
                          disabled={!isCompany}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
              <CardActions>
                <ProgressButton
                  color="primary"
                  variant="contained"
                  loading={isOrgLoading}
                  disabled={!orgForm.formState.isValid || !can(Permission.UPDATE_ORGANIZATION)}
                  type="submit"
                >
                  Save Changes
                </ProgressButton>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </Grid>
    </CustomerLayout>
  );
};
