import { ReactElement } from 'react';


/**
 * Add your markers here!
 * Maker names must be specific to its use, so
 * DO NOT REUSE MARKERS IN DIFFERENT CONTEXTS i.e. do not add generic markers like 'button-ok'
 *
 * These names are potentially customer facing so chose carefully
 */
export const MARKER_IDS = {
  STACK_WIDGET_1: 'stack_widget_1',
  STACK_WIDGET_2: 'stack_widget_2',
  AUTH_TOKEN_VIEWER: 'auth_token_viewer',
  ADMIN_TOS_IP_ADDRESS: 'admin_tos_ip_address',
  TOS_OVERLAY: 'tos_overlay',
  MAGIC_TABLE: 'magic_table',
  IMAGE_SLIDE: 'image_slider',
  PRO_STARTER_RENAME_INFO: 'pro_starter_rename_info',
  REGION_DROPDOWN: 'region_dropdown',
  MUTATE_BUTTON: 'mutate_button',
  SIGNUP_NEXT: 'signup_next',
  SUBSCRIPTIONS_TABLE: 'subscriptions_table',
} as const;

export type MarkerName = typeof MARKER_IDS[keyof typeof MARKER_IDS];

export const TestMarker = ({ name }: { name: MarkerName }): ReactElement => <span data-marker-name={name} />;

export const TestMarkerSpan = ({ name, children }: {
  name: MarkerName,
  children: ReactElement | ReactElement[] | string
}): ReactElement =>
  <span data-marker-name={name}>
    {children}
  </span>;
