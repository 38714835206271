// Explicitly exported here to avoid confusion.
// create-react-app will try to load these from .env files at build time

const ensure = <A>(a?: A): A => {
  if (!a) {
    throw Error('Missing environment variable');
  }
  return a;
};

export const SERVICE_BASE_URL = ensure(process.env.REACT_APP_SERVICE_BASE_URL);
export const BASE_URL = ensure(process.env.REACT_APP_BASE_URL);
export const STRIPE_PUBLIC_KEY = ensure(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
export const CONTACT_EMAIL = ensure(process.env.REACT_APP_CONTACT_EMAIL);
export const CURRENCY = ensure(process.env.REACT_APP_CURRENCY);
export const RELEASE_VERSION = ensure(process.env.REACT_APP_RELEASE_VERSION);
export const RECAPTCHA_API_KEY = ensure(process.env.REACT_APP_RECAPTCHA_API_KEY);

// SSO
export const COGNITO_CLIENT_ID = ensure(process.env.REACT_APP_COGNITO_CLIENT_ID);
export const COGNITO_DOMAIN = ensure(process.env.REACT_APP_COGNITO_DOMAIN);
export const COGNITO_IDENTIFIER = ensure(process.env.REACT_APP_COGNITO_IDENTIFIER);

// Hubspot
export const HUBSPOT_TRACKING_CODE = ensure(process.env.REACT_APP_HUBSPOT_TRACKING_CODE);
