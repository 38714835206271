import { CloudPodsService, getSelectedInstance, useApiGetter, useAwsEffect } from '@localstack/services';
import { ControlledSelect, ControlledTextField, ProgressButton, StatusIndicator } from '@localstack/ui';
import { Box, Card, CardActions, CardContent, CardHeader, MenuItem, Switch, Tooltip, Typography } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { LocalStackEvent, ProductFeature } from '@localstack/types';

import { retrieveAuthToken } from '~/util/storage';
import { useAuthProvider } from '~/hooks';

import { DISABLED_TEAM_TOOLTIP_TEXT } from '../common';

type PodFormProps = {
  podName: string;
};

type ExistingPodFormProps = {
  existingPodName: string;
};

type ExportToCloudPodCardProps = {
  disabled: boolean;
};

export const ExportToCloudPodCard = ({ disabled }: ExportToCloudPodCardProps): ReactElement => {
  const [useExistingPod, setUseExistingPod] = useState(false);
  const [logOutput, setLogOutput] = useState<LocalStackEvent[]>([]);
  const [hasSavedPod, setHasSavedPod] = useState(false);

  const { hasFeature } = useAuthProvider();
  const hasTeamSubscription = hasFeature(ProductFeature.FEATURE_PODS);

  const {
    data: allPods,
    isLoading: isPodLoading,
    mutate,
  } = useApiGetter(CloudPodsService, 'listCloudPods', [], { defaultValue: [] });

  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};

  const { savePodToRemote, isLoading, hasError } = useAwsEffect('LocalStack', ['savePodToRemote'], {
    silentErrors: false,
    clientOverrides,
  });

  const { control, handleSubmit, watch } = useForm<PodFormProps | ExistingPodFormProps>();
  const podName = watch(useExistingPod ? 'existingPodName' : 'podName');

  const disableButtons = !hasTeamSubscription || !podName || disabled;

  const onSubmit = async (data: PodFormProps) => {
    setLogOutput([]);
    const authToken = retrieveAuthToken();
    setHasSavedPod(false);
    setLogOutput([{ event: 'status', message: 'Saving pod...' }]);
    await savePodToRemote(data.podName, null, authToken?.token);
    setHasSavedPod(true);
    mutate();
  };

  useEffect(() => {
    if (hasSavedPod && !isPodLoading && hasError) {
      setLogOutput((prevLogOutput) => [...prevLogOutput, { event: 'error', message: 'Error saving pod' }]);
    }
    if (hasSavedPod && !isPodLoading && !hasError) {
      setLogOutput((prevLogOutput) => [...prevLogOutput, { event: 'status', message: 'Pod successfully saved' }]);
    }
  }, [hasSavedPod, isLoading, hasError]);

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Save your LocalStack State to a Cloud Pod" />
        <CardContent>
          <Typography variant="body1" color="textSecondary">
            Want to save your current LocalStack to a new or existing Pod?
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <Typography>New Pod</Typography>
            <Switch
              color="primary"
              checked={useExistingPod}
              onChange={(_, checked: boolean) => setUseExistingPod(checked)}
            />
            <Typography>Existing Pod</Typography>
          </Box>
          {useExistingPod && !isPodLoading && allPods ? (
            <ControlledSelect
              variant="outlined"
              control={control}
              fullWidth
              label="Existing Pod"
              name="existingPodName"
              options={allPods.map((pod) => (
                <MenuItem key={pod.id} value={pod.pod_name}>
                  {pod.pod_name}
                </MenuItem>
              ))}
            />
          ) : (
            <ControlledTextField
              fullWidth
              label="Pod Name"
              variant="outlined"
              name="podName"
              control={control}
              rules={
                !useExistingPod
                  ? {
                    validate: (name: string): string | boolean =>
                      !allPods?.some((pod) => pod.pod_name === name) ||
                        // eslint-disable-next-line max-len
                        'Name already taken by another pod - you can create a new version for it by switching the toggle above',
                  }
                  : undefined
              }
            />
          )}
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          {(isLoading || hasSavedPod) && (
            <StatusIndicator isProgressing={isLoading} logEvents={logOutput} hasError={hasError && !isLoading} />
          )}
          {!isLoading && <div />}
          <Tooltip title={disableButtons ? DISABLED_TEAM_TOOLTIP_TEXT : ''}>
            <div>
              <ProgressButton
                disabled={disableButtons}
                loading={isLoading}
                variant="outlined"
                color="primary"
                type="submit"
                startIcon={<CloudUploadIcon />}
              >
                Create new Pod {useExistingPod ? 'version' : ''}
              </ProgressButton>
            </div>
          </Tooltip>
        </CardActions>
      </form>
    </Card>
  );
};
