/* eslint-disable no-console */
import { useState, ReactElement, useEffect, useMemo, useRef } from 'react';
import {
  AwsClientOverrides,
  getSelectedInstance,
  satisfiesVersionConstraint,
  useAwsEffect,
  useAwsGetter,
  useLocalstackStatus,
  useSnackbar,
} from '@localstack/services';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Switch,
  TextField,
  Theme,
  Typography,
  Alert, Autocomplete,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AwsServiceIcon, InstanceSelector } from '@localstack/ui';

import classNames from 'classnames';

import {
  GeneratedPolicy,
  LocalStackInstance,
  RESOURCES_SERVICES_TYPE,
  PolicyStreamResponse,
  AggregatedPolices,
  FeatureMaturityLevel,
} from '@localstack/types';


import { SERVICE_CODES } from '@localstack/constants';

import { CustomerLayout } from '~/layouts';

import { MemoizedPolicyListItem, MemoizedPolicySummaryListItem } from './components';

const MIN_REQUIRED_VERSION = '3.0.0';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    onlineIndicator: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    onlineIndicatorOnline: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    dangerButton: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
    },
    centerActions: {
      alignItems: 'center',
      justifyContent: 'right',
    },
    centerShowMore: {
      justifyContent: 'center',
    },
    chip: {
      margin: theme.spacing(0.3),
    },
    autocomplete: {
      minWidth: '200px',
      maxWidth: '300px',
    },
  }),
);

const { community: communityList, pro: proList } = RESOURCES_SERVICES_TYPE;
const SUPPORTED_RESOURCES = [...communityList, ...proList].sort();
const LIMIT_CALLS = 25;

export const IAMStream = (): ReactElement => {
  const classes = useStyles();

  const [policies, setPolicies] = useState<AggregatedPolices[]>([]);
  const [summary, setSummary] = useState<GeneratedPolicy[]>([]);
  const [expandAllOutput, setExpandAllOutput] = useState<boolean>(false);
  const [expandAllSummary, setExpandAllSummary] = useState<boolean>(false);
  const [servicesFilter, setServicesFilter] = useState<string[]>([]);
  const [numberOfShowMore, setNumberOfShowMore] = useState<number>(1);
  const { showSnackbar } = useSnackbar();
  const defaultInstance = getSelectedInstance();
  const policiesRef = useRef<AggregatedPolices[]>([]);

  const [
    clientOverrides,
    setClientOverrides,
  ] = useState<AwsClientOverrides>(defaultInstance ? { endpoint: defaultInstance.endpoint } : {});

  const {
    enableIAMPolicyStream,
    disableIAMPolicyStream,
    resetIAMPolicySummary,
  } = useAwsEffect(
    'LocalStack',
    ['enableIAMPolicyStream', 'disableIAMPolicyStream', 'resetIAMPolicySummary'],
    { revalidate: ['getIAMPolicyStreamConfig'], silentErrors: true, clientOverrides },
  );

  const { running: isLocalStackRunning, version } = useLocalstackStatus(clientOverrides);

  const { openPolicySummaryStream, openPolicyStream } =
    useAwsEffect(
      'LocalStack',
      ['openPolicySummaryStream', 'openPolicyStream'],
      { silentErrors: true, clientOverrides },
    );
  const [openStreamReaders, setOpenStreamReaders] = useState<Optional<ReadableStreamDefaultReader<Uint8Array>>[]>([]);
  const {
    data: policyStatus,
    mutate,
  } = useAwsGetter('LocalStack', 'getIAMPolicyStreamConfig', [], { clientOverrides, silentErrors: true });

  const {
    policy_engine_enabled,
    enforce_iam,
    iam_soft_mode,
  } = policyStatus ?? {};

  const streamStatus = policy_engine_enabled ? 'Stream active' : 'Offline';

  const hasMinVersion = satisfiesVersionConstraint(version || '0', MIN_REQUIRED_VERSION);

  const memoizedSummary = useMemo(() => summary, [summary]);
  const filteredPolicies = useMemo(() =>
    policies.filter(request => servicesFilter.length === 0 ||
      servicesFilter.includes(request.request.service)).reverse(), [servicesFilter, policies]);

  const endOfListText = `Make some API calls to see the generated policies ${policies.length > 0 ?
    `(showing 0 out of ${policies.length} calls due to filtering)` : ''}`;
  const handleResetStream = async (): Promise<void> => {
    await resetIAMPolicySummary();
    setPolicies([]);
  };

  const handleEnableDisableEngine = async () => {
    if (policy_engine_enabled) {
      await handleResetStream();
      disableIAMPolicyStream();
      setSummary([]);
    } else {
      enableIAMPolicyStream();
    }
  };

  useEffect(() => { policiesRef.current = policies; }, [policies]);

  const handleIncomingPolicies = (streamResponse: PolicyStreamResponse[]) => {
    const aggregatedPolicies = [] as AggregatedPolices[];
    const aggregationLimit = -20;
    const recentPolicies = policiesRef.current.slice(aggregationLimit);
    streamResponse.forEach((chunk) => {
      const combinedPolicies = [...recentPolicies, ...aggregatedPolicies];
      const foundRequest = combinedPolicies.find(item => item.request.id === chunk.request.id);
      if (foundRequest) {
        foundRequest.policies.push(chunk.policy);
      } else {
        aggregatedPolicies.push({ request: chunk.request, policies: [chunk.policy], isExpanded: expandAllOutput });
      }
    });


    setPolicies((current) => [...current.slice(0, aggregationLimit), ...recentPolicies, ...aggregatedPolicies]);
  };

  const createSnackbarError = (error: string) => showSnackbar({ message: error, severity: 'error' });

  const updateExpandStatus = (isExpanded: boolean, requestId?: string) => {
    setPolicies((prevArray) => prevArray.map((item) =>
      !requestId || item.request.id === requestId
        ? { ...item, isExpanded }
        : item,
    ),
    );
  };

  useEffect(() => {
    const openStreams = async () => {
      const readerStream = await openPolicyStream(
        handleIncomingPolicies,
        createSnackbarError,
      );
      const readerSummary = await openPolicySummaryStream(
        setSummary,
        createSnackbarError,
      );
      setOpenStreamReaders([readerStream, readerSummary]);
    };

    openStreamReaders.forEach(reader => reader?.cancel());

    if (isLocalStackRunning && hasMinVersion) {
      setSummary([]);
      setPolicies([]);
      openStreams();
    }

    return () => openStreamReaders.forEach(reader => reader?.cancel());
  }, [clientOverrides, hasMinVersion]);


  useEffect(() => {
    mutate();
  }, [isLocalStackRunning]);


  const handleInstanceChange = (instance: LocalStackInstance) => {
    resetIAMPolicySummary(); // Need to do it here before endpoint changes
    setClientOverrides({ endpoint: instance.endpoint });
  };

  useEffect(() => updateExpandStatus(expandAllOutput), [expandAllOutput]);

  return (
    <CustomerLayout title="IAM Policy Stream" planFamily={FeatureMaturityLevel.PREVIEW}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Typography variant='inherit'>
                  IAM Policy Stream
                </Typography>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>
                    The IAM Policy Stream shows you exactly which permissions are needed for each API call.
                    This makes it easy to explore and progressively tighten security as your application takes shape.
                    Focus on building first, then dial in permissions when you&apos;re ready.
                  </Typography>
                </Grid>
                <Grid item xs={3} xl={3}>
                  <InstanceSelector
                    callback={handleInstanceChange}
                    hideIfOne
                  />
                </Grid>
                {isLocalStackRunning && !hasMinVersion &&
                  <Grid item xs={12}>
                    <Alert
                      severity='error'
                      style={{ width: '100%' }}
                    >
                      The IAM policy stream requires LocalStack version 3.0.0 or higher.
                    </Alert>
                  </Grid>
                }
                {!isLocalStackRunning &&
                  <Grid item xs={12}>
                    <Alert
                      severity='warning'
                      style={{ width: '100%' }}
                    >
                      This LocalStack instance is not running. Start LocalStack to use this feature
                    </Alert>
                  </Grid>
                }
              </Grid>
            </CardContent>
            <CardActions disableSpacing>
              {policy_engine_enabled &&
                <Button
                  onClick={handleResetStream}
                  className={classes.dangerButton}
                  variant='outlined'
                >
                  Reset Policy Stream
                </Button>
              }
              {(!enforce_iam && !iam_soft_mode && isLocalStackRunning) &&
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleEnableDisableEngine}
                  disabled={!hasMinVersion}
                >
                  {policy_engine_enabled ? 'Disable' : 'Enable'}
                </Button>
              }
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title='Summary Policy'
              action={
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          value={expandAllSummary}
                          onChange={(e) => setExpandAllSummary(e.target.checked)}
                        />
                      }
                      label={expandAllSummary ? 'collapse all' : 'expand all'}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={classNames(classes.onlineIndicator,
                        { [classes.onlineIndicatorOnline]: policy_engine_enabled },
                      )}
                      label={streamStatus}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <List>
                {
                  memoizedSummary.length > 0 &&
                  (memoizedSummary.map(policy => (
                    <MemoizedPolicySummaryListItem summary={policy} expand={expandAllSummary} key={policy.resource} />
                  )))
                }
              </List>
            </CardContent>
          </Card>
        </Grid>
        {/* Single Policy List */}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title='Output'
              action={
                <Grid
                  container
                  className={classes.centerActions}
                  spacing={1}
                >
                  <Grid item>
                    <Autocomplete
                      className={classes.autocomplete}
                      multiple
                      size='small'
                      disablePortal
                      options={SUPPORTED_RESOURCES}
                      getOptionLabel={(r) => r}
                      onChange={(_, e) => setServicesFilter(e)}
                      renderInput={(options) => <TextField variant="outlined" label="Select Services" {...options} />}
                      value={servicesFilter}
                      renderTags={(values) => values.map(service => (
                        <Chip
                          className={classes.chip}
                          onDelete={() => setServicesFilter((prev) => prev.filter(item => item !== service))}
                          label={
                            <AwsServiceIcon
                              code={service}
                              title={SERVICE_CODES[service as keyof typeof SERVICE_CODES]}
                              size='medium'
                            />
                          }
                        />
                      ))}
                      limitTags={2}
                      renderOption={(props, service) => (
                        <li {...props}>
                          <Box display='flex' className={classes.centerActions}>
                            <AwsServiceIcon
                              code={service}
                              title={SERVICE_CODES[service as keyof typeof SERVICE_CODES]}
                              size='medium'
                            />
                            <Typography>{SERVICE_CODES[service as keyof typeof SERVICE_CODES]}</Typography>
                          </Box>
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          value={expandAllOutput}
                          onChange={(e) => setExpandAllOutput(e.target.checked)}
                        />
                      }
                      label={expandAllOutput ? 'collapse all' : 'expand all'}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      className={classNames(classes.onlineIndicator,
                        { [classes.onlineIndicatorOnline]: policy_engine_enabled },
                      )}
                      label={streamStatus}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box style={{ overflowX: 'auto' }}>
                    <List dense subheader={
                      <>
                        <ListSubheader>{policies.length} Requests (newest on top)</ListSubheader>
                        <Divider />
                      </>
                    }
                    >
                      {policyStatus && filteredPolicies.length > 0 ?
                        (
                          <>
                            {filteredPolicies.slice(0, LIMIT_CALLS * numberOfShowMore).map(request =>
                              <MemoizedPolicyListItem
                                aggregatedPoliciesRequest={request}
                                updateExpandStatus={updateExpandStatus}
                                policyStatusConfig={policyStatus}
                                key={request.request.id}
                              />,
                            )}

                            {filteredPolicies.length > LIMIT_CALLS * numberOfShowMore && (
                              <ListItem
                                key='Show More Button'
                                className={classes.centerShowMore}
                              >
                                <Button onClick={() => setNumberOfShowMore(current => current + 1)}>
                                  Show {LIMIT_CALLS} More Requests
                                </Button>
                              </ListItem>
                            )}
                          </>
                        ) :
                        <ListItem key='No requests'>
                          <ListItemText>
                            {endOfListText}
                          </ListItemText>
                        </ListItem>
                      }
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid >
    </CustomerLayout >
  );
};
