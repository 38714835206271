import { useState, ReactElement, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { red } from '@mui/material/colors';
import { IdentityProvider, MembershipRole, OrganizationSSOSettings } from '@localstack/types';
import { PERMISSION_GROUPS, PERMISSION_TYPES, ExternalLink } from '@localstack/constants';
import { useForm } from 'react-hook-form';

import {
  ControlledCheckbox,
  ControlledDropzone,
  ControlledSelect,
  ControlledTextField,
  ProgressButton,
  LoadingFragment,
  CopyIconButton,
} from '@localstack/ui';

import {
  VALIDATION_RULES,
  useApiGetter,
  useApiEffect,
  OrganizationsService,
  UserService,
} from '@localstack/services';

import {
  DeleteOutlined as DeleteIcon,
  AddCircle as AddIcon,
} from '@mui/icons-material';

import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  MenuItem,
  CardActions,
  TextField,
  Select,
  Link,
  Alert, Chip,
} from '@mui/material';

import { COGNITO_DOMAIN, COGNITO_IDENTIFIER, BASE_URL } from '~/config';
import { useAuthProvider } from '~/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      alignItems: 'center',
    },
    memberCard: {
      border: 'none',
      boxShadow: 'none',
    },
    sectionItem: {
      marginTop: '1rem',
    },
    disabledCopyField: {
      '& > div': {
        color: `${theme.palette.text.primary} !important`,
      },
    },
  }),
);

const fileToBase64 = (file: File) => new Promise<string | undefined>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string | undefined);
  reader.onerror = error => reject(error);
});

interface FormData {
  idp: IdentityProvider;
  sso_settings: OrganizationSSOSettings;
}

export const SSOSettings = (): ReactElement => {
  const classes = useStyles();

  const { userInfo } = useAuthProvider();
  const organization = userInfo?.org;
  const organizationId = organization?.id;

  const { data: identityProviders, isLoading: isIdpLoading } = useApiGetter(
    OrganizationsService,
    'listIdentityProviders',
    [organizationId],
    {
      enable: !!organizationId,
      defaultValue: [] },
  );

  const { storeIdentityProvider, deleteIdentityProvider, isLoading: isIdpUpdating } = useApiEffect(
    OrganizationsService,
    ['storeIdentityProvider', 'deleteIdentityProvider'],
    { revalidate: ['listIdentityProviders', 'listOrganizations'] },
  );

  const { updateOrganizationSettings, isLoading: isOrgUpdating } = useApiEffect(
    OrganizationsService,
    ['updateOrganizationSettings'],
    {
      revalidate: ['listOrganizations'],
      revalidateOtherClient: { client: UserService, methods: ['getUser'] },
    },
  );

  const isLoading = isIdpLoading || isIdpUpdating || isOrgUpdating;

  const { control, handleSubmit, formState, reset, setValue, watch } =
    useForm<FormData>({ mode: 'all' });

  const [addNewProvider, setAddNewProvider] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<Optional<IdentityProvider>>(null);
  const [metadataType, setMetadataType] = useState<string>('MetadataURL');

  const providerType: string = watch('idp.provider_type');
  const idpName = watch('idp.idp_name') ?? '';
  const isStrictMode = watch('sso_settings.strict_sso_mode');

  const onSubmit = async (data: FormData) => {
    const { idp, sso_settings } = data;

    if (idp.provider_type === 'SAML' && idp.MetadataFile) {
      const fileAsBase64 = await fileToBase64(idp.MetadataFile as unknown as File);
      idp.MetadataFile = fileAsBase64;
    }

    const updatedSettings = {
      ...organization?.settings,
      sso_settings: {
        ...organization?.settings?.sso_settings,
        [idp.idp_name]: sso_settings,
      },
    };

    await storeIdentityProvider(organizationId, idp.idp_name, idp);
    await updateOrganizationSettings(organizationId, updatedSettings);

    setAddNewProvider(false);
    setSelectedProvider(null);
  };

  const onAddNewProvider = () => {
    setAddNewProvider(true);
    setSelectedProvider(null);
  };

  const onSelectProvider = (idp: IdentityProvider) => {
    if (idp.idp_name === selectedProvider?.idp_name) return;
    setSelectedProvider(idp);
    setAddNewProvider(false);
  };

  // set fields of form to values of selected provider
  useEffect(() => {
    // reset the form if we are adding a new provider
    if (!selectedProvider) return reset();

    // prefill idp. fields
    Object.keys(selectedProvider).forEach(field => {
      const castedField = field as keyof IdentityProvider;
      if((castedField === 'MetadataFile' || castedField === 'MetadataURL') && selectedProvider?.[castedField]) {
        setMetadataType(castedField);
        if (castedField === 'MetadataFile') return;
      }
      setValue(
        `idp.${castedField}`as keyof FormData,
        // eslint-disable-next-line
        selectedProvider?.[castedField] as any,
        { shouldValidate: true },
      );
    });

    if (!organization) return;

    // prefill sso_settings. fields
    const ssoSettings = organization.settings?.sso_settings?.[selectedProvider.idp_name];
    Object.entries(ssoSettings ?? {}).forEach(([field, value]) => {
      setValue(`sso_settings.${field}` as keyof FormData, value, { shouldValidate: true });
    });
  }, [selectedProvider]);

  const callbackUrl = `https://${COGNITO_DOMAIN}.auth.eu-central-1.amazoncognito.com/`+
    `${providerType === 'SAML' ? 'saml2' : 'oauth2'}/idpresponse`;
  const signUpPortalUrl = `${BASE_URL}/auth/sso/${organizationId}/${selectedProvider?.idp_name || idpName || ''}`;

  return (
    <Card>
      <CardHeader
        title="Single Sign-on Identity Providers"
        action={
          <Box display="flex" alignItems="center">
            <Button
              color="primary"
              size="small"
              disabled={addNewProvider}
              onClick={onAddNewProvider}
              startIcon={<AddIcon />}
            >
              Add new Identity Provider
            </Button>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={4} sm={12}>
            <LoadingFragment
              height={65}
              size={3}
              loading={isIdpLoading}
              arrayData={identityProviders ?? []}
              emptyText="No identity providers configured."
              emptyAction={
                <Button color="primary" size="small" onClick={() => setAddNewProvider(true)}>
                  Create
                </Button>
              }
            />
            <List>
              {identityProviders?.map((idp: IdentityProvider) => (
                <ListItem
                  key={idp.idp_name}
                  button
                  onClick={() => onSelectProvider(idp)}
                  selected={idp.idp_name === selectedProvider?.idp_name}
                >
                  <ListItemText
                    primary={idp.idp_name}
                    secondary={idp.provider_type}
                  />
                  {organization?.settings?.sso_settings?.[idp.idp_name]?.strict_sso_mode && (
                    <Chip color="warning" label="Strict SSO Mode" size="small" />
                  )}
                </ListItem>
              ))}
              <ListItem
                component={Link}
                button
                color="primary"
                target="_blank"
                href={ExternalLink.DOCS_SSO}
              >
                <ListItemText
                  primary="Find out more about how to configure your identity providers in our documentation"
                />
              </ListItem>
            </List>
          </Grid>
          {(selectedProvider || addNewProvider) && (
            <Grid item md={8} sm={12}>
              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Card className={classes.memberCard}>
                  <CardHeader
                    title={
                      <Typography variant="subtitle1" noWrap>
                        <strong>
                          {addNewProvider
                            ? 'New identity provider'
                            : selectedProvider?.idp_name}
                        </strong>
                      </Typography>
                    }
                    subheader={addNewProvider ? '' : selectedProvider?.provider_type}
                    action={
                      <>
                        {!addNewProvider && (
                          <Button
                            size="small"
                            style={{ color: red.A200 }}
                            startIcon={<DeleteIcon />}
                            onClick={async () => {
                              await deleteIdentityProvider(
                                organization?.id as string,
                                selectedProvider?.idp_name as string,
                              );
                              setSelectedProvider(null);
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </>
                    }
                  />
                  <CardContent>
                    {isStrictMode && (
                      <Box mb={2}>
                        <Alert severity="warning">
                          Strict SSO Mode is enabled.
                          Authentication for this workspace and its members is limited to this Identity Provider only.
                        </Alert>
                      </Box>
                    )}
                    <Box display="flex" alignItems="center">
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <ControlledTextField
                            control={control}
                            fullWidth
                            label="Identity provider name"
                            name="idp.idp_name"
                            disabled={selectedProvider !== null}
                            variant="outlined"
                            type="text"
                            required
                            rules={{
                              ...VALIDATION_RULES.required,
                              validate: (value: Optional<string>): string | boolean =>
                                (!value || /^[a-zA-Z0-9_-]+$/.test(value) ||
                                  'Must only include alphanumerical characters, hyphens, and underscores') }}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <ControlledSelect
                            variant="outlined"
                            control={control}
                            required
                            fullWidth
                            disabled={selectedProvider !== null}
                            label="Provider type"
                            name="idp.provider_type"
                            options={[
                              <MenuItem key="OIDC" value='OIDC'>OpenID Connect</MenuItem>,
                              <MenuItem key="SAML" value='SAML'>SAML</MenuItem>,
                            ]}
                            rules={VALIDATION_RULES.required}
                          />
                        </Grid>
                        {providerType === 'OIDC' && (
                          <>
                            <Grid item md={12}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="Client ID"
                                name="idp.client_id"
                                variant="outlined"
                                type="text"
                                required
                                rules={VALIDATION_RULES.required}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="Client secret"
                                name="idp.client_secret"
                                variant="outlined"
                                type="text"
                                required
                                rules={VALIDATION_RULES.required}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <ControlledSelect
                                variant="outlined"
                                control={control}
                                fullWidth
                                label="Attributes request method"
                                name="idp.attributes_request_method"
                                options={[
                                  <MenuItem key="GET" value='GET'>GET</MenuItem>,
                                  <MenuItem key="POST" value='POST'>POST</MenuItem>,
                                ]}
                                required
                                rules={VALIDATION_RULES.required}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="OIDC issuer"
                                name="idp.oidc_issuer"
                                variant="outlined"
                                type="text"
                                required
                                rules={{ ...VALIDATION_RULES.required, ...VALIDATION_RULES.https }}
                              />
                              {/* <Grid item xs={4}>
                                <Button variant='contained'>Run discovery</Button>
                              </Grid> */}
                            </Grid>
                            <Grid item md={12}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="Authorize scopes"
                                variant="outlined"
                                name="idp.authorize_scopes"
                                type="text"
                                required
                                rules={{
                                  ...VALIDATION_RULES.required,
                                  validate: (value: string): string | boolean =>
                                    value.includes('openid') || 'openid is required',
                                }}
                              />
                              <Typography align='right' variant='body2' style={{ marginTop: '2px' }}>
                                Values for authorize scopes must be comma- or space-separated depending
                                on your identity provider
                              </Typography>
                            </Grid>
                            <Grid item md={12}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="Authorize URL"
                                name="idp.authorize_url"
                                variant="outlined"
                                type="text"
                                required
                                rules={{ ...VALIDATION_RULES.required, ...VALIDATION_RULES.https }}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="Token URL"
                                name="idp.token_url"
                                variant="outlined"
                                type="text"
                                required
                                rules={{ ...VALIDATION_RULES.required, ...VALIDATION_RULES.https }}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="Attributes URL"
                                name="idp.attributes_url"
                                variant="outlined"
                                type="text"
                                required
                                rules={{ ...VALIDATION_RULES.required, ...VALIDATION_RULES.https }}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="JWKS URI"
                                name="idp.jwks_uri"
                                variant="outlined"
                                type="text"
                                required
                                rules={{ ...VALIDATION_RULES.required, ...VALIDATION_RULES.https }}
                              />
                            </Grid>
                          </>
                        )}
                        {providerType === 'SAML' && (
                          <>
                            <Grid item md={12}>
                              <Typography>
                                Metadata file<sup>*</sup>
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Select
                                    label="Metadata Type"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(event) => setMetadataType(event.target.value as string)}
                                    value={metadataType}
                                  >
                                    <MenuItem key="URL" value="MetadataURL">URL</MenuItem>
                                    <MenuItem key="FILE" value="MetadataFile">File</MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item xs={8}>
                                  {metadataType === 'MetadataFile' && (
                                    <ControlledDropzone
                                      control={control}
                                      fullWidth
                                      name="idp.MetadataFile"
                                      rules={VALIDATION_RULES.required}
                                    />
                                  )}
                                  {metadataType === 'MetadataURL' && (
                                    <ControlledTextField
                                      control={control}
                                      fullWidth
                                      label="Metadata File URL"
                                      name="idp.MetadataURL"
                                      variant="outlined"
                                      type="text"
                                      required
                                      rules={VALIDATION_RULES.required}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={12}>
                              <Box>
                                <ControlledCheckbox
                                  control={control}
                                  label="Enable IdP sign out flow"
                                  name="idp.IDPSignout"
                                  color="primary"
                                />
                              </Box>
                            </Grid>
                          </>
                        )}
                        <Grid item md={12} className={classes.sectionItem}>
                          <Box>
                            <ControlledCheckbox
                              control={control}
                              label="Enable Strict SSO Mode"
                              name="sso_settings.strict_sso_mode"
                              color="primary"
                            />
                            <Typography variant="caption" component="div">
                              If enabled, it will only be possible to authenticate or join the workspace
                              using this identity provider. All other means of authentication will be disabled.
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item md={12} className={classes.sectionItem}>
                          <Typography variant="subtitle1">
                            Attribute Mapping
                          </Typography>
                          <Typography variant="subtitle2">
                            In order to let users log in from your organization
                            you need to map user attributes from your identity
                            provider to the corresponding attributes below
                          </Typography>
                        </Grid>
                        <Grid item md={12}>
                          <Grid container spacing={2}>
                            <Grid item md={6} component={Typography} align='center'>
                              Your attributes
                            </Grid>
                            <Grid item md={6} component={Typography} align='center'>
                              Our attributes
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={12}>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                required
                                label="Email attribute"
                                name="idp.attribute_mapping.email"
                                variant="outlined"
                                type="text"
                                rules={VALIDATION_RULES.required}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                label="Email"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={12}>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="First name attribute"
                                name="idp.attribute_mapping.given_name"
                                variant="outlined"
                                type="text"
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                label="First name"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={12}>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <ControlledTextField
                                control={control}
                                fullWidth
                                label="Last name attribute"
                                name="idp.attribute_mapping.family_name"
                                variant="outlined"
                                type="text"
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                label="Last name"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={12} className={classes.sectionItem}>
                          <Typography variant="subtitle1">
                            Callback URL
                          </Typography>
                          <Typography variant="subtitle2" paragraph>
                            In order to configure your identity providers
                            you may need the following callback URL
                          </Typography>
                          <TextField
                            variant="outlined"
                            disabled
                            fullWidth
                            className={classes.disabledCopyField}
                            value={callbackUrl}
                            InputProps={{
                              endAdornment:
                              <CopyIconButton
                                textToCopy={callbackUrl}
                                tooltipTitle="Copy callback URL"
                              />,
                            }}
                          />
                        </Grid>
                        <Grid item md={12} className={classes.sectionItem}>
                          <Typography variant="subtitle1">
                            Identifier (Entity Id)
                          </Typography>
                          <Typography variant="subtitle2" paragraph>
                            In order to configure your identity providers
                            you may need the following Cognito identifier
                          </Typography>
                          <TextField
                            variant="outlined"
                            disabled
                            fullWidth
                            className={classes.disabledCopyField}
                            value={COGNITO_IDENTIFIER}
                            InputProps={{
                              endAdornment:
                              <CopyIconButton
                                textToCopy={COGNITO_IDENTIFIER}
                                tooltipTitle="Copy cognito identifier"
                              />,
                            }}
                          />
                        </Grid>
                        <Grid item md={12} className={classes.sectionItem}>
                          <Typography variant="subtitle1">
                            Sign Up Portal
                          </Typography>
                          <Typography variant="subtitle2" paragraph>
                            Users from your organization can sign up
                            through this identity provider via below link
                          </Typography>
                          <TextField
                            variant="outlined"
                            disabled
                            fullWidth
                            className={classes.disabledCopyField}
                            value={signUpPortalUrl}
                            InputProps={{
                              endAdornment:
                              <CopyIconButton
                                textToCopy={signUpPortalUrl}
                                tooltipTitle="Copy Sign Up Portal URL"
                              />,
                            }}
                          />
                          <Typography variant="caption">
                            After signing up, users can also sign in normally on
                            {' '}
                            <a target="_blank" rel="noreferrer" href={ExternalLink.WEB_APP_SIGN_IN}>
                              app.localstack.cloud
                            </a>
                          </Typography>
                        </Grid>
                        <Grid item md={12} className={classes.sectionItem}>
                          <Typography variant="subtitle1">
                            Sign Up Settings
                          </Typography>
                          <Box mt={2}>
                            <ControlledSelect
                              variant="outlined"
                              control={control}
                              fullWidth
                              label="Default User Role"
                              name="sso_settings.default_membership_role"
                              options={[
                                <MenuItem key={MembershipRole.MEMBER} value={MembershipRole.MEMBER}>Member</MenuItem>,
                                <MenuItem key={MembershipRole.ADMIN} value={MembershipRole.ADMIN}>Admin</MenuItem>,
                              ]}
                            />
                            <Typography variant="caption">
                              If specified, all users from this identity provider
                              will be assigned selected role upon sign up, default
                              is &apos;MEMBER&apos; role
                            </Typography>
                          </Box>
                          <Box mt={2}>
                            <ControlledSelect
                              variant="outlined"
                              control={control}
                              fullWidth
                              multiple
                              label="Default User Permissions"
                              name="sso_settings.default_membership_permissions"
                              options={
                                Object.entries(PERMISSION_GROUPS).reduce((memo, [group, permissions]) => [
                                  ...memo,
                                  <MenuItem disabled key={group} value={group}>{group}</MenuItem>,
                                  ...permissions.map(
                                    (permission) => (
                                      <MenuItem key={permission} value={permission}>
                                        {PERMISSION_TYPES[permission as keyof typeof PERMISSION_TYPES]}
                                      </MenuItem>
                                    ),
                                  ),
                                ], [] as JSX.Element[])
                              }
                            />
                            <Typography variant="caption">
                              If specified, all users from this identity provider
                              will be <strong>additionally</strong> assigned
                              selected permissions upon sign up
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <ProgressButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!formState.isValid}
                      loading={isLoading}
                    >
                      Save
                    </ProgressButton>
                  </CardActions>
                </Card>
              </form>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
