import { ReactElement } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { FileCopyOutlined as CopyIcon } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from '@localstack/services';

export interface CopyIconButtonProps {
  textToCopy: string;
  tooltipTitle: string;
  button?: ReactElement
}


export const CopyIconButton = ({ textToCopy, tooltipTitle, button }: CopyIconButtonProps): ReactElement => {
  const { showSnackbar } = useSnackbar();

  return (
    <CopyToClipboard
      text={textToCopy}
      onCopy={() => showSnackbar({
        message: 'Copied to clipboard',
        severity: 'info',
      })}
    >
      <Tooltip title={tooltipTitle}>
        {button ||
          <IconButton color="primary" size="large">
            <CopyIcon />
          </IconButton>
        }
      </Tooltip>
    </CopyToClipboard>
  );
};
